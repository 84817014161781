import axios from 'axios';
import React, { useState, useEffect } from 'react';
import logo from "../assets/cara.png";
import { useNavigate } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';

export default function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginStatus, setLoginStatus] = useState('');
  const navigate = useNavigate();

  const handleSignIn = async () => {
    try {
      const response = await axios.post('https://lasttouch.meqne.com/api/login/', {
        username,
        password,
      });

      if (response.status === 200) {
        const token = response.data.token;
        localStorage.setItem("token", token);
        console.log("Authentication token:", token);
        const user = response.data.user;
        localStorage.setItem("user", JSON.stringify(user));
        navigate('/');
        setLoginStatus('success');
      } else {
        setLoginStatus('error');
      }
    } catch (error) {
      setLoginStatus('error');
    }
  };

  useEffect(() => {
    if (loginStatus === 'success') {
      toast.success('Login successful!');
    } else if (loginStatus === 'error') {
      toast.error('Invalid credentials. Please try again.');
    }
  }, [loginStatus]);

  return (
    <div className="flex h-screen items-center justify-center bg-gray-100">
      <div className="w-96 h-4/5 p-8 bg-white rounded-lg shadow-2xl">
        <div className="flex flex-col items-center">
          <img src={logo} className="w-24 h-24 mb-4" alt="Logo" />
          <h2 className="text-3xl font-semibold text-gray-800">Synergy Login</h2>
        </div>
        <div className="mt-8">
          <div className="mb-2">
            <label htmlFor="username" className="text-gray-800">
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full border border-gray-300 bg-gray-100 text-sm h-10 px-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your username"
            />
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="text-gray-800">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full border border-gray-300 bg-gray-100 text-sm h-10 px-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your password"
            />
          </div>
          <div className="flex justify-center">
            <button
              id="signin"
              name="signin"
              className="bg-synergy-orange hover:bg-orange-700 text-white font-bold py-2 px-16 rounded focus:outline-none"
              onClick={handleSignIn}
            >
              Sign In
            </button>
          </div>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
}