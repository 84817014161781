// import React from 'react';



// // {messages.map((message) => (
// //     <div key={message.id} className="bg-gray-100 p-4 my-2">
// //       <p>{message.content}</p>
// //       <p className="text-sm text-gray-500">
// //         From: {message.sender.name} ({message.sender.role})
// //       </p>
// //     </div>
// //   ))}
// const ManagerMessageList = ({ messages }) => {
//   return (
//     <div>
//       <h2>Message List</h2>
 
//     </div>
//   );
// };

// export default ManagerMessageList;


import React, { useState, useEffect } from 'react';

const ManagerMessageList = ({ messages }) => {
  const [notification, setNotification] = useState(false);

  useEffect(() => {
    // Check if there are new messages
    const hasNewMessages = messages.length > 0;

    // Set notification state
    setNotification(hasNewMessages);
  }, [messages]);

  return (
    <div>
      <h2>Message List</h2>
      {notification && <p className="text-red-500">New message received!</p>}
      {messages.map((message) => (
        <div key={message.id} className="bg-gray-100 p-4 my-2">
          <p>{message.content}</p>
          <p className="text-sm text-gray-500">
            From: {message.sender.name} ({message.sender.role})
          </p>
        </div>
      ))}
    </div>
  );
};

export default ManagerMessageList;
