// import React, { useState, useEffect } from 'react';
// import test7 from "../assets/test7.png";
// import test9 from "../assets/test9.jpeg";
// import test10 from "../assets/test10.jpeg";

// const ProductListt = () => {
//   const [activeAccordion, setActiveAccordion] = useState(null);
//   const [blogPosts, setBlogPosts] = useState([]);

//   useEffect(() => {
//     fetch('http://127.0.0.1:8000/api/blog-posts-getall/')
//       .then(response => response.json())
//       .then(data => setBlogPosts(data))
//       .catch(error => console.log(error));
//   }, []);

//   const products = [
//     {
//       id: 1,
//       name: 'Product 1',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//     },
//     {
//       id: 2,
//       name: 'Product 2',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//     },
//     {
//       id: 3,
//       name: 'Product 3',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//     },
//   ];

//   const handleAccordion = (productId) => {
//     setActiveAccordion(productId === activeAccordion ? null : productId);
//   };

//   return (
//     <div className="container mx-auto">
//       <h1 className="text-xl font-bold my-4">Product List</h1>
//       {products.map((product) => (
//         <div key={product.id} className="border rounded-lg p-2 mb-2 hover:bg-blue-400">
//           <div
//             className="flex items-center justify-between cursor-pointer"
//             onClick={() => handleAccordion(product.id)}
//           >
//             <h3 className="text-sm font-semibold">{product.name}</h3>
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               className={`h-4 w-4 transform ${activeAccordion === product.id ? 'rotate-180' : ''}`}
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M19 9l-7 7-7-7"
//               />
//             </svg>
//           </div>
//           {activeAccordion === product.id && (
//             <div className="mt-2">
//               <p className="text-sm">{product.description}</p>
//             </div>
//           )}
//         </div>
//       ))}

//       <div className="border rounded-lg p-2 mt-4">
//         <h2 className="text-xl font-bold mb-2">Our Brand and Insights</h2>
//         <p className="text-sm">
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.
//         </p>
//       </div>

//       <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 mt-4">
//         {blogPosts.map((post) => (
//           <SupplierCard
//             key={post.id}
//             image={post.image}
//             name={post.title}
//             description={post.description}
//           />
//         ))}
//       </div>
//     </div>
//   );
// };

// const SupplierCard = ({ image, name, description }) => {
//   return (
//     <div className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg">
//       <img className="w-full h-32 object-cover" src={image} alt={name} />
//       <div className="p-2">
//         <h3 className="text-lg font-medium mb-2">{name}</h3>
//         <p className="text-gray-600 text-sm">{description}</p>
//       </div>
//     </div>
//   );
// };

// export default ProductListt;


import React, { useState, useEffect } from 'react';
import {BarLoader, BeatLoader, BounceLoader, CircleLoader, ClimbingBoxLoader, ClipLoader, ClockLoader, DotLoader, FadeLoader, GridLoader, HashLoader, MoonLoader, PacmanLoader, PropagateLoader, PuffLoader, PulseLoader, RingLoader, RiseLoader, RotateLoader, ScaleLoader, SkewLoader, SquareLoader, SyncLoader } from 'react-spinners'; // Replace 'Spinner' with the actual component you're using for the loading spinner
import test7 from "../assets/test7.png";
import test9 from "../assets/test9.jpeg";
import test10 from "../assets/test10.jpeg";
import LoadingLogo from './LoadingLogo';
import axios from 'axios';
const ProductListt = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [blogPosts, setBlogPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add a state variable for loading status


  useEffect(() => {
    if (window.performance.navigation.type === PerformanceNavigation.TYPE_RELOAD) {
      window.location.href = '/';
    }
  }, []);





  const fetchTestimonials = () => {
    axios
      .get('http://127.0.0.1:8000/api/acordio-getall/')
      .then((response) => {
        setBlogPosts(response.data);
        setIsLoading(false); // Set loading state to false when data is fetched
      })
      .catch((error) => {
        console.error('Error fetching testimonials:', error);
        setIsLoading(false); // Set loading state to false even if there's an error
      });
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);









  

  const products = [
    {
      id: 1,
      name: 'Product 1',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
    },
    {
      id: 2,
      name: 'Product 2',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
    },
    {
      id: 3,
      name: 'Product 3',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
    },
  ];

  const handleAccordion = (productId) => {
    setActiveAccordion(productId === activeAccordion ? null : productId);
  };

  return (
    <div className="container mx-auto">
      <h1 className="text-xl font-bold my-4">Product List</h1>
      {isLoading ? ( // Render the loading spinner if loading is true
        <LoadingLogo />
      ) : (
        <>
          {products.map((product) => (
            <div key={product.id} className="border rounded-lg p-2 mb-2 hover:bg-blue-400">
              <div
                className="flex items-center justify-between cursor-pointer"
                onClick={() => handleAccordion(product.id)}
              >
                <h3 className="text-sm font-semibold">{product.name}</h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-4 w-4 transform ${activeAccordion === product.id ? 'rotate-180' : ''}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </div>
              {activeAccordion === product.id && (
                <div className="mt-2">
                  <p className="text-sm">{product.description}</p>
                </div>
              )}
            </div>
          ))}

          <div className="border rounded-lg p-2 mt-4">
            <h2 className="text-xl font-bold mb-2">Our Brand and Insights</h2>
            <p className="text-sm">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 mt-4">
            {blogPosts.map((post) => (
              <SupplierCard
                key={post.id}
                image={post.image}
                name={post.title}
                description={post.description}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
const handleChangePicture = (postId) => {
  console.log(`Change picture clicked for blog post ${postId}`);
  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    if (imageFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Image = e.target.result;

        const title = prompt('Enter the new title:');
        const description = prompt('Enter the new description:');

        if (title && description) {
          const token = localStorage.getItem('token');
          const headers = {
            Authorization: `token ${token}`,
          };

          axios
            .put(`http://127.0.0.1:8000/api/acordio/${postId}/`, { image: base64Image, title, description }, { headers })
            .then((response) => {
              console.log('Image changed successfully:', response.data);
              fetchTestimonials(); // Refresh the blog post list
            })
            .catch((error) => {
              console.error('Error changing image:', error);
            });
        }
      };
      reader.readAsDataURL(imageFile);
    }
  };

  const inputElement = document.createElement('input');
  inputElement.type = 'file';
  inputElement.accept = 'image/*';
  inputElement.addEventListener('change', handleImageChange);
  inputElement.click();
};



const handleAddImage = () => {
  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    if (imageFile) {
      const title = prompt('Enter the title:');
      const description = prompt('Enter the description:');
    
      if (title && description) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Image = e.target.result;

          const formData = new FormData();
          formData.append('image', base64Image);
          formData.append('title', title);
          formData.append('description', description);
   
    

          const token = localStorage.getItem('token');
          const headers = {
            Authorization: `token ${token}`,
            'Content-Type': 'multipart/form-data',
          };

          axios
            .post('http://127.0.0.1:8000/api/acordio/create/', formData, { headers })
            .then((response) => {
              console.log('Image added successfully:', response.data);
              fetchTestimonials(); // Refresh the blog post list
            })
            .catch((error) => {
              console.error('Error adding image:', error);
            });
        };
        reader.readAsDataURL(imageFile);
      }
    }
  };

  const inputElement = document.createElement('input');
  inputElement.type = 'file';
  inputElement.accept = 'image/*';
  inputElement.addEventListener('change', handleImageChange);
  inputElement.click();
};


const handleDelete = (postId) => {
  console.log(`Delete button clicked for blog post ${postId}`);
  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `token ${token}`,
  };

  axios
    .delete(`http://127.0.0.1:8000/api/acordio/${postId}/delete/`, { headers })
    .then((response) => {
      console.log('Blog post deleted successfully:', response.data);
      fetchTestimonials(); // Refresh the blog post list
    })
    .catch((error) => {
      console.error('Error deleting blog post:', error);
    });
};








const isLoggedIn = !!(localStorage.getItem('token') && localStorage.getItem('user'));
const SupplierCard = ({ image, name, description }) => {
  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg">
      <img className="w-full h-32 object-cover" src={image} alt={name} />
      <div className="p-2">
        <h3 className="text-lg font-medium mb-2">{name}</h3>
        <p className="text-gray-600 text-sm">{description}</p>
      </div>
      {isLoggedIn && (
        <div className="p-2 flex flex-row justify-end gap-2">
          <button
            className="text-white hover:bg-blue-800 mr-2 bg-blue-600 rounded-lg px-4 py-1"
            onClick={() => handleChangePicture(blogPost.id)}
          >
            Edit
          </button>
          <button
            className="text-white hover:bg-red-800 mr-2 bg-red-600 rounded-lg px-4 py-1"
            onClick={() => handleDelete(blogPost.id)}
          >
            Delete
          </button>
        </div>
      )}

      
    </div>


    
  );
};

export default ProductListt;







// import React, { useState, useEffect } from 'react';
// import test7 from "../assets/test7.png";
// import test9 from "../assets/test9.jpeg";
// import test10 from "../assets/test10.jpeg";
// import LoadingLogo from './LoadingLogo';

// const ProductListt = () => {
//   const [activeAccordion, setActiveAccordion] = useState(null);
//   const [blogPosts, setBlogPosts] = useState([]);
  

//   useEffect(() => {
//     fetch('http://127.0.0.1:8000/api/blog-posts-getall/')
//       .then(response => response.json())
//       .then(data => {
//         setBlogPosts(data);
     
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   }, []);

//   const products = [
//     {
//       id: 1,
//       name: 'Product 1',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//     },
//     {
//       id: 2,
//       name: 'Product 2',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//     },
//     {
//       id: 3,
//       name: 'Product 3',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//     },
//   ];

//   const handleAccordion = (productId) => {
//     setActiveAccordion(productId === activeAccordion ? null : productId);
//   };

//   return (
//     <div className="container mx-auto">
//       <h1 className="text-xl font-bold my-4">Product List</h1>

//         <>
//           {products.map((product) => (
//             <div key={product.id} className="border rounded-lg p-2 mb-2 hover:bg-blue-400">
//               <div
//                 className="flex items-center justify-between cursor-pointer"
//                 onClick={() => handleAccordion(product.id)}
//               >
//                 <h3 className="text-sm font-semibold">{product.name}</h3>
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   className={`h-4 w-4 transform ${activeAccordion === product.id ? 'rotate-180' : ''}`}
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke="currentColor"
//                 >
//                   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
//                 </svg>
//               </div>
//               {activeAccordion === product.id && (
//                 <div className="mt-2">
//                   <p className="text-sm">{product.description}</p>
//                 </div>
//               )}
//             </div>
//           ))}

//           <div className="border rounded-lg p-2 mt-4">
//             <h2 className="text-xl font-bold mb-2">Our Brand and Insights</h2>
//             <p className="text-sm">
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.
//             </p>
//           </div>

//           <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 mt-4">
//             {blogPosts.map((post) => (
//               <SupplierCard
//                 key={post.id}
//                 image={post.image}
//                 name={post.title}
//                 description={post.description}
//               />
//             ))}
//           </div>
//         </>
     
//     </div>
//   );
// };

// const SupplierCard = ({ image, name, description }) => {
//   return (
//     <div className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg">
//       <img className="w-full h-32 object-cover" src={image} alt={name} />
//       <div className="p-2">
//         <h3 className="text-lg font-medium mb-2">{name}</h3>
//         <p className="text-gray-600 text-sm">{description}</p>
//       </div>
//     </div>
//   );
// };

// export default ProductListt;