import React, { useRef, useState, useEffect } from 'react';
import { FiLock, FiLogOut,FiUserPlus } from 'react-icons/fi';
// import { Link } from 'react-router-dom';
import { redirect } from "react-router-dom";
import { Link } from 'react-router-dom';


const NavAvatar = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));
  const role = JSON.parse(localStorage.getItem('role'));
  const popupRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handlePasswordChange = () => {
    // return <redi to="/changepassword" />;
    return redirect("/changepassword");

    // window.location.href = "/changepassword";
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location = '/'; // Redirect to homepage
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <div className="relative">
      <button
        className="flex items-center justify-center w-12 h-12 rounded-full bg-synergy-orange focus:outline-none"
        onClick={togglePopup}
      >
        {/* Add your avatar image here */}
        {user && user.username[0].toUpperCase()}
      </button>

      {isPopupOpen && (
        <div className="absolute right-0 mt-2 py-2 w-48 bg-blue-800 rounded-lg shadow-xl" ref={popupRef}>
          <div className="flex flex-col items-center justify-center px-8">
            <h3 className="text-lg mt-3 font-bold text-white">
            
              HI, {user.username.toUpperCase()}
            
            </h3>
            <h3 className="text-xs mb-3 text-white">
            {user && typeof user.email === 'string' && user.email.toLowerCase()}
            </h3>
          </div>
          
          
          <div>
          <Link to="/changepassword">
          <button
            className="flex items-center justify-center px-4 py-2 text-black hover:bg-gray-100 text-sm rounded-lg w-full"
          >
            <FiLock className="mr-2" />
            Change Password
          </button>
        </Link>
          </div>
          {
            role === 1 && 
              (
              <Link to="/RegisterForm">
                <button
                  className="flex items-center justify-center px-4 py-2 text-black hover:bg-gray-100 text-sm rounded-lg w-full"
                >
                  <FiUserPlus className="mr-2" />
                  Register User
                </button>
              </Link>
            )} 

          <button
            className="flex items-center justify-center  py-2 text-black hover:bg-gray-100 text-sm rounded-lg w-full"
            onClick={handleLogout}
          >
            <FiLogOut className="mr-2" />
            Logout
          </button>
        </div>
        
      )}
      





    </div>
  );
};

export default NavAvatar;