import React from 'react';
import "../styles/JournalSection.css";
import { Link } from 'react-router-dom';
import test7 from "../assets/test7.png";
import test9 from "../assets/test9.jpeg";
import test10 from "../assets/test10.jpeg";
import { useEffect } from 'react';

const JournalSection = () => {
    useEffect(() => {
        const handleBeforeUnload = (event) => {
          event.preventDefault();
          // Redirect to the home page
          window.location.href = '/';
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);
   
    return (
        <div className='jsMainParent'>

            <div className='jsLeft'>
                <img src= {test7} alt='hey' className=' rounded-xl w-72' />
                <p className=' mt-8'> Delivering World-Class Engineering Solutions Locally! <br />
                     Experience the Difference with Our Highly <br />
                      Qualified and Experienced Team of<br />
                     Engineers and Technicians!</p>
                      
                <Link to={`/journal/april`}>
                    <button className='readBtn '> READ MORE </button>
                </Link>
            </div>

            <div className='jsRight flex flex-row gap-2'>

                <Link to={`/journal/april`}>
                    <img src= {test9} alt='hey' className='jsImg rounded-xl' />
                </Link>
                <Link to={`/journal/april`}>
                <img src= {test10} alt='hey' className='jsImg rounded-xl' />
                </Link>

            </div>

            <div className='jsRightText flex flex-row gap-3 relative font-bold'>
                <p>Supply and Installation of OEM Spare <br /> Parts for Factories </p>
                <p>Maintenance and Rehabilitation<br/> of Industrial Control Systems
                </p>

                                 </div>
        </div>
    )
}

export default JournalSection