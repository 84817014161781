import React from 'react'
import i1 from "../assets/download.png";
import i2 from "../assets/download (2).png";
import i3 from "../assets/download (3).png";
import i4 from "../assets/download (4).png";
import i5 from "../assets/download (5).png";
import i6 from "../assets/download (6).png";
import i11 from "../assets/download 15.png";
import i12 from "../assets/download 11.png";
import i13 from "../assets/download 12.png";
import i14 from "../assets/download 13.png";
import i15 from "../assets/download 14.png";
import i16 from "../assets/download 15.png";
import i17 from "../assets/download 16.png";




import { ExternalLink } from 'react-external-link';
import Marquee from "react-fast-marquee";


const Someofourcustomers = () => {
 return (
  
    <div className='flex gap-3 mt-4 flex-wrap mb-10'>
    <div class="flex flex-row text-2xl font-bold justify-center items-center" style={{textAlign: 'center', width: '100%'}}>
    Our Suppliers
</div>
      <div>
        <Marquee pauseOnHover={true} speed={100}>
          <ExternalLink href='#' className='tab-rounded-lg'>
            <img src={i1} className='w-40 rounded-2xl mrMl carouselIMg' />
          </ExternalLink>

          <ExternalLink href='#' className='tab-rounded-lg'>
            <img src={i2} className='w-40 rounded-2xl mrMl carouselIMg' />
          </ExternalLink>

          <ExternalLink href='#' className='tab-rounded-lg'>
            <img src={i3} className='w-40 rounded-2xl mrMl carouselIMg' />
          </ExternalLink>

          <ExternalLink href='#' className='tab-rounded-lg'>
            <img src={i4} className='w-40 rounded-2xl mrMl carouselIMg' />
          </ExternalLink>
          <ExternalLink href='#' className='tab-rounded-lg'>
          <img src={i5} className='w-40 rounded-2xl mrMl carouselIMg' />
        </ExternalLink>
        <ExternalLink href='#' className='tab-rounded-lg'>
        <img src={i6} className='w-40 rounded-2xl mrMl carouselIMg' />
      </ExternalLink>
      <ExternalLink href='#' className='tab-rounded-lg'>
      <img src={i11} className='w-40 rounded-2xl mrMl carouselIMg' />
      <ExternalLink href='#' className='tab-rounded-lg'>
      <img src={i12} className='w-40 rounded-2xl mrMl carouselIMg' />
    </ExternalLink>
    <ExternalLink href='#' className='tab-rounded-lg'>
    <img src={i13} className='w-40 rounded-2xl mrMl carouselIMg' />
  </ExternalLink>
  <ExternalLink href='#' className='tab-rounded-lg'>
  <img src={i14} className='w-40 rounded-2xl mrMl carouselIMg' />
  <ExternalLink href='#' className='tab-rounded-lg'>
  <img src={i15} className='w-40 rounded-2xl mrMl carouselIMg' />
  <ExternalLink href='#' className='tab-rounded-lg'>
  <img src={i16} className='w-40 rounded-2xl mrMl carouselIMg' />
</ExternalLink>
</ExternalLink>
</ExternalLink>
    </ExternalLink>

        </Marquee>
      </div>
    </div>
  )
}

export default Someofourcustomers


// import React from 'react';
// import Marquee from "react-fast-marquee";
// import i1 from "../assets/i1.jpg";
// import i2 from "../assets/i2.png";
// import i3 from "../assets/i3.jpg";
// import i4 from "../assets/i4.jpg";

// const Someofourcustomers = () => {
//   return (
//     <div className="w-full">
//       <Marquee pauseOnHover={true} speed={100}>
//         <img src={i1} className="w-40 h-40 mr-4" alt="Image 1" />
//         <img src={i2} className="w-40 h-40 mr-4" alt="Image 2" />
//         <img src={i3} className="w-40 h-40 mr-4" alt="Image 3" />
//         <img src={i4} className="w-40 h-40 mr-4" alt="Image 4" />
//         <img src={i4} className="w-40 h-40 mr-4" alt="Image 5" />
//       </Marquee>
//     </div>
//   );
// }

// export default Someofourcustomers;