import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AboutUsPage = () => {
  const [aboutData, setAboutData] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    // Add other fields as per your requirements
  });

  useEffect(() => {
    fetchAboutData();
  }, []);

  const fetchAboutData = async () => {
    try {
      const response = await axios.get('/api/about');
      setAboutData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post('/api/about', formData);
      fetchAboutData();
      setFormData({
        title: '',
        description: '',
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/about/${id}`);
      fetchAboutData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container mx-auto">
      <h1 className="text-3xl font-bold mb-4">About Us</h1>

      <form onSubmit={handleSubmit} className="mb-4">
        <input
          type="text"
          placeholder="Title"
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          className="border border-gray-300 rounded p-2 mr-2"
        />

        <textarea
          placeholder="Description"
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
          className="border border-gray-300 rounded p-2 mr-2"
        />

        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Add
        </button>
      </form>

      {aboutData.map((about) => (
        <div key={about.id} className="mb-4 p-4 border border-gray-300">
          <h2 className="text-xl font-bold">{about.title}</h2>
          <p>{about.description}</p>
          <button
            onClick={() => handleDelete(about.id)}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-2"
          >
            Delete
          </button>
        </div>
      ))}
    </div>
  );
};

export default AboutUsPage;