import React from 'react'
import { useState ,useEffect } from 'react'
import axios from 'axios';


function Newtest() {
// const [test,setTest] = useState([]);

// useEffect(()=>{
//   fetch('http://127.0.0.1:8000/api/testimonials-getall/')
//   .then(response =>response.json())
//   .then(data=>setTest(data))
//   // .then(console.log(response)) 
//   .catch(err=>console.log(err))
//   // .then((data)=>console.log(response.data))

// }, [])
  return (
    <div>Newtest</div>
  )
}

export default Newtest