// import React, { useState } from 'react';
// import { jsPDF } from 'jspdf';

// function FormComponent() {
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [phone, setPhone] = useState('');
//   const [message, setMessage] = useState('');
//   const [address, setAddress] = useState('');
//   const [city, setCity] = useState('');
//   const [zip, setZip] = useState('');

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Perform form submission logic here
//     console.log('Name:', name);
//     console.log('Email:', email);
//     console.log('Phone:', phone);
//     console.log('Message:', message);
//     console.log('Address:', address);
//     console.log('City:', city);
//     console.log('Zip:', zip);
//     // Reset the form fields
//     setName('');
//     setEmail('');
//     setPhone('');
//     setMessage('');
//     setAddress('');
//     setCity('');
//     setZip('');
//   };

//   const handleDownloadPDF = () => {
//     const doc = new jsPDF();
//     doc.text('Form Details:', 10, 10);
//     doc.text(`Name: ${name}`, 10, 20);
//     doc.text(`Email: ${email}`, 10, 30);
//     doc.text(`Phone: ${phone}`, 10, 40);
//     doc.text(`Message: ${message}`, 10, 50);
//     doc.text(`Address: ${address}`, 10, 60);
//     doc.text(`City: ${city}`, 10, 70);
//     doc.text(`Zip: ${zip}`, 10, 80);
//     doc.save('form.pdf');
//   };

//   return (
//     <div className="flex justify-center items-center h-screen">
//       <div id="formCard" className="bg-white shadow-lg rounded-lg p-6">
//         <h2 className="text-xl font-bold mb-4">Form Component</h2>

//         <form onSubmit={handleSubmit}>
//           <div className="mb-4">
//             <label htmlFor="name">Name:</label>
//             <input
//               type="text"
//               id="name"
//               value={name}
//               onChange={(e) => setName(e.target.value)}
//               required
//             />
//           </div>

//           <div className="mb-4">
//             <label htmlFor="email">Email:</label>
//             <input
//               type="email"
//               id="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               required
//             />
//           </div>

//           <div className="mb-4">
//             <label htmlFor="phone">Phone:</label>
//             <input
//               type="tel"
//               id="phone"
//               value={phone}
//               onChange={(e) => setPhone(e.target.value)}
//               required
//             />
//           </div>

//           <div className="mb-4">
//             <label htmlFor="message">Message:</label>
//             <textarea
//               id="message"
//               value={message}
//               onChange={(e) => setMessage(e.target.value)}
//               required
//             />
//           </div>

//           <div className="mb-4">
//             <label htmlFor="address">Address:</label>
//             <input
//               type="text"
//               id="address"
//               value={address}
//               onChange={(e) => setAddress(e.target.value)}
//               required
//             />
//           </div>

//           <div className="mb-4">
//             <label htmlFor="city">City:</label>
//             <input
//               type="text"
//               id="city"
//               value={city}
//               onChange={(e) => setCity(e.target.value)}
//               required
//             />
//           </div>

//           <div className="mb-4">
//             <label htmlFor="zip">Zip Code:</label>
//             <input
//               type="text"
//               id="zip"
//               value={zip}
//               onChange={(e) => setZip(e.target.value)}
//               required
//             />
//           </div>

//           <div className="flex justify-between">
//             <button
//               type="submit"
//               className="bg-blue-500 text-white px-4 py-2 rounded"
//             >
//               Submit
//             </button>
//             <button
//               type="button"
//               className="bg-green-500 text-white px-4 py-2 rounded"
//               onClick={handleDownloadPDF}
//             >
//               Download PDF
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// }

// export default FormComponent;

import React, { useState } from 'react';
import { jsPDF } from 'jspdf';
import logo from "../assets/Droga.jpg";

function FormComponent() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form submission logic here
    console.log('Name:', name);
    console.log('Email:', email);
    console.log('Phone:', phone);
    console.log('Message:', message);
    console.log('Address:', address);
    console.log('City:', city);
    console.log('Zip:', zip);
    // Reset the form fields
    setName('');
    setEmail('');
    setPhone('');
    setMessage('');
    setAddress('');
    setCity('');
    setZip('');
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    const logoImg = new Image();
    const slogan = 'Your Slogan Here';

    logoImg.src = logo; // Replace 'logo' with the imported logo path

    logoImg.onload = function () {
      doc.addImage(logoImg, 'PNG', 85, 10, 25, 25); // Adjust the position and size of the logo
      doc.text(slogan, 70, 44); // Adjust the position of the slogan
      doc.text('Form Details:', 10, 70);
      doc.text(`Name: ${name}`, 10, 80);
      doc.text(`Email: ${email}`, 10, 90);
      doc.text(`Phone: ${phone}`, 10, 100);
      doc.text(`Message: ${message}`, 10, 110);
      doc.text(`Address: ${address}`, 10, 120);
      doc.text(`City: ${city}`, 10, 130);
      doc.text(`Zip: ${zip}`, 10, 140);
      doc.save('form.pdf');
    };
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div id="formCard" className="bg-white shadow-lg rounded-lg p-6">
        <h2 className="text-xl font-bold mb-4">Droga Pharma Form</h2>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="phone">Phone:</label>
            <input
              type="tel"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="address">Address:</label>
            <input
              type="text"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="city">City:</label>
            <input
              type="text"
              id="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="zip">Zip Code:</label>
            <input
              type="text"
              id="zip"
              value={zip}
              onChange={(e) => setZip(e.target.value)}
              required
            />
          </div>

          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Submit
          </button>
        </form>

        <button
          type="button"
          className="bg-green-500 text-white px-4 py-2 rounded"
          onClick={handleDownloadPDF}
        >
          Download PDF
        </button>
      </div>
    </div>
  );
}

export default FormComponent;