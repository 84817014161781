import { useState } from 'react';
import logo from "../assets/cara.png";
const PopupComponent = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isOpeen, setIsOpeen] = useState(false);

  const handleTogglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleClickP = () => {
    setIsOpeen(!isOpeen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location = '/';; // Redirect to homepage
  };

  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  return (
    <div className="relative">
      <button
        className="flex h-10 w-10 ml-6 items-center justify-center rounded-full bg-dro_white border-dro_black"
        onClick={handleTogglePopup}
      >
        {user && user.username[0].toUpperCase()}
      </button>

      {isPopupOpen && (
        <div className="absolute right-0 mt-2 w-64 bg-dro_white shadow-lg flex flex-col items-center py-3">
          <div className="rounded-full border-dro_black bg-dro_gray h-10 w-10 flex items-center justify-center">
            <span className="text-center">{user && user.username[0].toUpperCase()}</span>
          </div>
          <h3 className="text-lg mt-3 font-bold">
            {user && user.username.toUpperCase()}
          </h3>
          <h3 className="text-xs mb-3">{user && user.email}</h3>

          <div className="flex flex-col">
            <div></div>
            <div className="flex flex-row items-center justify-center">
              <Image src={logo} height={20} width={20} />
              <button className="hover:bg-dro_gray font-medium py-2 px-4">
                Change Password
              </button>
            </div>

            {/* Change Password Popup */}
            {isPopupOpen && (
              <div className="fixed inset-0 flex items-center justify-center bg-dro_white bg-opacity-75 blur-background backdrop-filter">
                <div className="h-96 w-1/3 bg-dro_yellow shadow-lg">
                  <div className="flex flex-col justify-center items-center h-full">
                    <h2 className="text-2xl font-bold mb-4">Change Password</h2>
                    <div className="relative px-10 py-3 h-52 w-full space-y-4 flex flex-col justify-center items-center">
                      <button
                        className="absolute right-1/3 top-14 bg-dro_red text-dro_white px-4 flex justify-start items-start"
                        onClick={handleTogglePopup}
                      >
                        X
                      </button>
                      <input
                        className="w-full h-14 rounded border border-dro_gray px-4 text-md text-dro_black focus:outline-none focus:border-blue-500"
                        type="password"
                        placeholder="Old Password"
                      />
                      <div className="relative">
                        <input
                          className="h-14 w-full rounded border border-dro_gray px-4 text-md text-dro_black focus:outline-none focus:border-blue-500 pr-10"
                          type="password"
                          placeholder="New Password"
                        />
                        <svg
                          className="absolute right-3 top-3 h-6 w-6 text-gray-400 cursor-pointer"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                          />
                        </svg>
                      </div>
                      <button className="flex justify-center items-center px-10 w-32 h-10 bg-dro_green text-white">
                        Enter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-row mr-24 ml-3 items-center justify-center">
              <Image src={logo} height={20} width={20} />
              <button className="hover:bg-dro_gray font-medium py-2 px-4" onClick={handleLogout}>
                Log Out
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopupComponent;