// import axios from 'axios';
// import React, { useState } from 'react';
// import logo from "../assets/cara.png";

// export default function LoginPage() {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');

//   const handleSignIn = async () => {
//     try {
//       const response = await axios.post('https://lasttouch.meqne.com/api/login/', {
//         username,
//         password,
//       });

//       if (response.status === 200) {
//         const token = response.data.token;
//         localStorage.setItem("token", token);
//         console.log("Authentication token:", token);
//         const user = response.data.user;
//         localStorage.setItem("user", JSON.stringify(user));
//         window.location.href = "/productlist";
//       } else {
//         // Handle unsuccessful login
//       }
//     } catch (error) {
//       // Handle any errors
//     }
//   };

//   return (
//     <div className="flex h-screen items-center justify-center bg-gray-100">
//       <div className="w-96 h-4/5 overflow-hidden bg-green-200 rounded-lg shadow-2xl">
//         <div className="flex flex-row items-center justify-center mt-3">
//           <img src={logo} className="w-20 h-auto hover:cursor-pointer rounded-lg" alt="Logo" />
//         </div>
//         <div className="pt-4 flex space-x-1 justify-center">
//           <div className="font-semibold text-3xl mb-1 flex justify-center text-black">
//             <span className="flex flex-col -space-x-3">Synergy</span>
//           </div>
//           <div className="font-semibold text-3xl mb-1 flex justify-center text-black">
//             Login
//           </div>
//         </div>
//         <div className="px-16 py-3">
//           <label htmlFor="username" className="text-black px-4">
//             Username
//           </label>
//         </div>
//         <div className="px-20">
//           <input
//             type="text"
//             id="username"
//             value={username}
//             onChange={(e) => setUsername(e.target.value)}
//             className="border border-gray-300 bg-white text-sm h-8 px-2 rounded"
//             placeholder=""
//           />
//         </div>
//         <div className="px-16 pt-4">
//           <label htmlFor="password" className="text-black px-4">
//             Password
//           </label>
//         </div>
//         <div className="px-20">
//           <input
//             type="password"
//             id="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             className="border border-gray-300 bg-white text-sm h-8 px-2 rounded"
//             placeholder=""
//           />
//         </div>
//         <div className="flex justify-center pt-8">
//           <button
//             id="signin"
//             name="signin"
//             className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-20 rounded"
//             onClick={handleSignIn}
//           >
//             Sign in
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }




import axios from 'axios';
import React, { useState } from 'react';
import logo from "../assets/cara.png";
import { useNavigate } from 'react-router-dom';

export default function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSignIn = async () => {
    setIsLoading(true); // Start loading spinner

    try {
      const response = await axios.post('https://api.synergyindustri.com/api/login/', {
        username,
        password,
      });

      if (response.status === 200) {
        const token = response.data.token;
        localStorage.setItem("token", token);
        console.log("Authentication token:", token);
        const user = response.data.user;
        localStorage.setItem("user", JSON.stringify(user));
        navigate('/');
        const role = response.data.role;
        localStorage.setItem("role", JSON.stringify(role));
        setErrorMessage('');
      } else {
        setIsLoading(false); // Stop loading spinner
        setErrorMessage('Bad request. Please check your login credentials.');
      }
    } catch (error) {
      setIsLoading(false); // Stop loading spinner
      setErrorMessage('Failed to log in. Please try again later.');
      console.error(error);
    }
  };

  return (
    <div className="flex h-screen items-center justify-center bg-gray-100">
      <div className="w-96 h-4/5 p-8 bg-white rounded-lg shadow-2xl">
        <div className="flex flex-col items-center">
          <img src={logo} className="w-24 h-24 mb-4" alt="Logo" />
          <h2 className="text-3xl font-semibold text-gray-800">Synergy Login</h2>
        </div>
        <div className="mt-8">
          <div className="mb-2">
            <label htmlFor="username" className="text-gray-800">
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full border border-gray-300 bg-gray-100 text-sm h-10 px-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your username"
            />
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="text-gray-800">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full border border-gray-300 bg-gray-100 text-sm h-10 px-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your password"
            />
          </div>
          <div className="flex justify-center">
            {errorMessage && <p className="text-red-500 mb-2">{errorMessage}</p>}
            {isLoading ? (
              <div className="flex items-center justify-center">
                <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-900"></div>
              </div>
            ) : (
              <button
                id="signin"
                name="signin"
                className="bg-synergy-orange hover:bg-orange-700 text-white font-bold py-2 px-16 rounded focus:outline-none"
                onClick={handleSignIn}
              >
                Sign In
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}




