import { useState, useEffect } from 'react';
import './LoadingLogo.css';
import logo from "../assets/cara.png";
import {BarLoader, BeatLoader, BounceLoader, CircleLoader, ClimbingBoxLoader, ClipLoader, ClockLoader, DotLoader, FadeLoader, GridLoader, HashLoader, MoonLoader, PacmanLoader, PropagateLoader, PuffLoader, PulseLoader, RingLoader, RiseLoader, RotateLoader, ScaleLoader, SkewLoader, SquareLoader, SyncLoader } from 'react-spinners';
// import { useState, useEffect } from 'react';


function LoadingLogo() {
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 25000); // Set the loading duration in milliseconds
  
      return () => clearTimeout(timer);
    }, []);
  
    return (
      <div className="flex flex-col items-center justify-center py-8 px-4 sm:px-6 lg:px-8 rounded-lg">
        <div className={`logo-spinner${isLoading ? ' flip' : ''}`}>
          <img src={logo} alt="Logo" className="logo w-24 h-24" />
       
        </div>
        <div className="text-synergy-orange"><BeatLoader/>
        </div>
      </div>
    );
  }
  
  export default LoadingLogo;