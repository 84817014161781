
import axios from 'axios';
import React, { useState } from 'react';
import logo from "../assets/Droga.jpg";
import { useNavigate } from 'react-router-dom';

export default function Drogalogin() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSignIn = async () => {
    setIsLoading(true); // Start loading spinner

    try {
      const response = await axios.post('http://127.0.0.1:8000/api/login/', {
        username,
        password,
      });

      if (response.status === 200) {
        const token = response.data.token;
        localStorage.setItem("token", token);
        console.log("Authentication token:", token);
        const user = response.data.user;
        localStorage.setItem("user", JSON.stringify(user));
        navigate('/Newsn');
        const role = response.data.role;
        localStorage.setItem("role", JSON.stringify(role));
        setErrorMessage('');
      } else {
        setIsLoading(false); // Stop loading spinner
        setErrorMessage('Bad request. Please check your login credentials.');
      }
    } catch (error) {
      setIsLoading(false); // Stop loading spinner
      setErrorMessage('Failed to log in. Please try again later.');
      console.error(error);
    }
  };

  return (
    <div className="flex h-screen items-center justify-center bg-dro_yellow">
      <div className="w-96 h-4/5 p-8 bg-white rounded-lg shadow-2xl">
        <div className="flex flex-col items-center">
          <img src={logo} className="w-24 h-24 mb-4" alt="Logo" />
          <h2 className="text-3xl font-semibold text-gray-800">Droga Login</h2>
        </div>
        <div className="mt-8">
          <div className="mb-2">
            <label htmlFor="username" className="text-gray-800">
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full border border-gray-300 bg-gray-100 text-sm h-10 px-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your username"
            />
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="text-gray-800">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full border border-gray-300 bg-gray-100 text-sm h-10 px-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your password"
            />
          </div>
          <div className="flex justify-center">
            {errorMessage && <p className="text-red-500 mb-2">{errorMessage}</p>}
            {isLoading ? (
              <div className="flex items-center justify-center">
                <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-900"></div>
              </div>
            ) : (
              <button
                id="signin"
                name="signin"
                className="bg-black hover:bg-dro_yellow text-white font-bold hover:text-black py-2 px-16 rounded focus:outline-none"
                onClick={handleSignIn}
              >
                Sign In
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}




