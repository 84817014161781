


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingLogo from './LoadingLogo';
const Newproducttoaboutus = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [loading, setLoading] = useState(true); // New loading state
    // const isLoggedIn = localStorage.getItem('token') && localStorage.getItem('user');
    const isLoggedIn = !!(localStorage.getItem('token') && localStorage.getItem('user'));
    
    useEffect(() => {
      fetchBlogPosts();
    }, []);

    const fetchBlogPosts = () => {
      const token = localStorage.getItem('token');
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
    
      if (token) {
        headers.Authorization = `token ${token}`;
      }
    
      axios
        .get('http://127.0.0.1:8000/api/blog-posts-getall/', { headers })
        .then((response) => {
          setBlogPosts(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching blog posts:', error);
          setLoading(false);
        });
    };

    // useEffect(() => {
    //   if (window.performance.navigation.type === PerformanceNavigation.TYPE_RELOAD) {
    //     window.location.href = '/';
    //   }
    // }, []);
  

  const handleChangePicture = (postId) => {
    console.log(`Change picture clicked for blog post ${postId}`);
    const handleImageChange = (event) => {
      const imageFile = event.target.files[0];
      if (imageFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Image = e.target.result;
  
          const title = prompt('Enter the new title:');
          const description = prompt('Enter the new description:');
  
          if (title && description) {
            const token = localStorage.getItem('token');
            const headers = {
              Authorization: `token ${token}`,
            };
  
            axios
              .put(`http://127.0.0.1:8000/api/blog/${postId}/`, { image: base64Image, title, description }, { headers })
              .then((response) => {
                console.log('Image changed successfully:', response.data);
                fetchBlogPosts(); // Refresh the blog post list
              })
              .catch((error) => {
                console.error('Error changing image:', error);
              });
          }
        };
        reader.readAsDataURL(imageFile);
      }
    };
  
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*';
    inputElement.addEventListener('change', handleImageChange);
    inputElement.click();
  };


  
  const handleAddImage = () => {
    const handleImageChange = (event) => {
      const imageFile = event.target.files[0];
      if (imageFile) {
        const title = prompt('Enter the title:');
        const description = prompt('Enter the description:');
  
        if (title && description) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const base64Image = e.target.result;
  
            const formData = new FormData();
            formData.append('image', base64Image);
            formData.append('title', title);
            formData.append('description', description);
  
            const token = localStorage.getItem('token');
            const headers = {
              Authorization: `token ${token}`,
              'Content-Type': 'multipart/form-data',
            };
  
            axios
              .post('http://127.0.0.1:8000/api/blog/create/', formData, { headers })
              .then((response) => {
                console.log('Image added successfully:', response.data);
                fetchBlogPosts(); // Refresh the blog post list
              })
              .catch((error) => {
                console.error('Error adding image:', error);
              });
          };
          reader.readAsDataURL(imageFile);
        }
      }
    };
  
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*';
    inputElement.addEventListener('change', handleImageChange);
    inputElement.click();
  };


  const handleDeletePost = (postId) => {
    console.log(`Delete button clicked for blog post ${postId}`);
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `token ${token}`,
    };
  
    axios
      .delete(`http://127.0.0.1:8000/api/blog/${postId}/delete/`, { headers })
      .then((response) => {
        console.log('Blog post deleted successfully:', response.data);
        fetchBlogPosts(); // Refresh the blog post list
      })
      .catch((error) => {
        console.error('Error deleting blog post:', error);
      });
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location = '/';; // Redirect to homepage
  };


  
  return (
    <div className="flex flex-wrap justify-center">
    {blogPosts.map((post) => (
        <div key={post.id} className="w-full p-4 flex">
        <div className="w-1/2">
          <img src={post.image} alt={post.name} className="h-48 w-full rounded-t-lg" />
        </div>
        <div className="w-1/2 p-4">
          <div className="overflow-y-auto bg w-full" style={{ maxHeight: '30px' }}>
            <p className="text-lg font-semibold">{post.title}</p>
          </div>
          <div className="overflow-y-auto bg w-full p-1" style={{ maxHeight: '70px' }}>
            <h3 className="mt-2 text-gray-600">{post.description}</h3>
          </div>
              {isLoggedIn && (
                <button
                  onClick={() => handleChangePicture(post.id)}
                  className="mt-2 bg-blue-500 text-sm px-2 py-1 rounded-lg text-white hover:cursor-pointer hover:bg-blue-600"
                >
                  Edit
                </button>
              )}
              {isLoggedIn && (
                <button
                  onClick={() => handleDeletePost(post.id)}
                  className="mt-2 bg-red-500 text-sm text-white px-2 py-1 rounded-lg ml-4 hover:cursor-pointer hover:bg-red-600"
                >
                  Delete
                </button>
              )}
            </div>
          </div>
        // </div>
    //   </div>
    ))}
    {isLoggedIn && (
      <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4">
        <div className="bg-white rounded-lg shadow-lg text-center">
          <div className="h-48 flex items-center justify-center">
            <button
              onClick={handleAddImage}
              className="bg-green-500 text-white px-4 py-2 rounded-lg"
            >
              Add Image
            </button>
          </div>
        </div>
      </div>
    )}
  </div>
  );
};

export default Newproducttoaboutus;




