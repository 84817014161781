import React from 'react';

const Journals = () => {
  const journals = [
    {
      id: 1,
      title: 'Journal 1',
      date: 'January 1, 2022',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
      image: '/journal1.jpg',
    },
    {
      id: 2,
      title: 'Journal 2',
      date: 'February 15, 2022',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
    //   image: 'C:/Users/pc/Desktop/Bring Desktop (1)/j/react-e-commerce--main/src/assets/1.jpg',
      image: require("C:/Users/pc/Desktop/Bring Desktop (1)/j/react-e-commerce--main/src/assets/1.jpg"),
    },
    {
      id: 3,
      title: 'Journal 3',
      date: 'March 10, 2022',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
      image: '/journal3.jpg',
    },
  ];

  return (
    <div className="container mx-auto">
      <h1 className="text-4xl font-bold my-8">Journals</h1>
      {journals.map((journal) => (
        <div key={journal.id} className="max-w-3xl mx-auto bg-white shadow-md rounded-lg overflow-hidden my-8">
          <img className="w-full h-48 object-cover" src={journal.image} alt={journal.title} />
          <div className="p-6">
            <h2 className="text-2xl font-bold mb-2">{journal.title}</h2>
            <p className="text-gray-600 mb-4">{journal.date}</p>
            <p className="text-gray-700">{journal.content}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Journals;
