// import React from 'react';
// import './Navbard.css'; // Import the CSS file for custom styles

// const Navbard = () => {
//   return (
//     <nav className="bg-gray-800 py-4">
//       <div className="container mx-auto flex justify-between items-center text-white">
//         <div className="logo">
//           <h1 className="text-2xl">Title</h1>
//           <h3 className="text-sm moving-text">Moving Text</h3>
//         </div>
//         <div className="links">
//           <ul className="flex">
//             <li className="mr-6 text-white"><a href="#">Home</a></li>
//             <li className="mr-6 text-white"><a href="#">About</a></li>
//             <li className="mr-6 text-white"><a href="#">Contact</a></li>
//           </ul>
//           </div>
//         <div className="profile flex items-center">
//           <h3 className="text-sm">User Profile</h3>
//           <img src="profile-image.jpg" alt="User Profile" className="w-8 h-8 rounded-full" />
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbard;






import React from 'react';
import './Navbard.css'; // Import the CSS file for custom styles
import logo from "../assets/Droga.jpg";
import NavAvatar from './NavAvatar';
import { Link } from 'react-router-dom';
const Navbard = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');
  return (

    <nav className="bg-gray-800 py-0 overflow-hidden -mt-1">
      <div className="container mx-auto flex justify-between items-center text-white z-10">
        <div className="logo">
   <div className='flex flex-row'>
   <img src={logo} className="w-10 h-10 mb-1" alt="Logo" />
   <h6 className="text-xl mt-3 px-2">DROGA</h6>
        </div>
          <h3 className="text-xs moving-text">serving </h3>
        </div>
        {user && token ? (
                           
          <div className='text-gray-100 hover:text-white px-3 py-1 rounded-md text-lg font-medium'>
          <NavAvatar/>
          </div>
        ) : (
          <Link to="/Drogalogin">
          <div className="text-black hover:bg-yellow-300 bg-dro_yellow hover:text-gray-500 px-3 py-1 rounded-md text-lg font-medium">Login</div>
          </Link>
        )

        }
      </div>
    </nav>

  );
};

export default Navbard;