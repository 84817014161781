import React from 'react';
import "../styles/JournalPage.css"
import hair from "../assets/hair.jpeg";
import better from "../assets/better.jpeg";
import { FaShippingFast } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { BsCurrencyDollar } from "react-icons/bs";
import JournalPageFooter from './JournalPageFooter';

const JournalPage = () => {
  return (<div className='journalPageMainParent-1'>
    <div className='journalPageMainParent'>
      <p className='sjText'> SYNERGY </p>

      <div className='paraHold absolute'>
        <p className='text-center font-serif'>"Synergy: Where Ideas Converge, Innovation Ignites!"

        Discover a world of endless possibilities at Synergy Journal, where the power of collaboration and innovation come together.</p>
      </div>

      <p className='fof text-3xl font-medium text-center absolute aprilBlogHeading'> Our Products and Services </p>

      <div className='hairImgHold'>
        <img src={hair} className="hairImg" alt='hey' />
      </div>

      <div className='jhold flex flex-col absolute'>

        <p> We recently completed supply works in 1.	OEM Spare Parts Supply for Water Treatment Plant for Coca Cola Beverages Africa, Addis Ababa Plant  
        2.	Automation Equipment and Components Supply for Moha Soft Drinks Industry, Addis Ababa.
        
        3.	Packaging Line Electrical Spare Parts Supply for Moha Nifas Silk Plant, Addis Ababa.
        
        4.	Supply of Control Valves for  Coca Cola Beverages Africa, Dire Dawa Plant  
        
        5.	Supply of compressor, motor and panel board components  for  Coca Cola Beverages Africa, Dire Dawa Plant  
        6.	Supply of PLCs and Instruments for  Coca Cola Beverages Africa, Addis Ababa  
        7.	Supply of Automation System Spare Parts (PLCs, Control Valves, UV lamps, Industrial PCs, etc) for Ambo Mineral Water S.C
        8.	Water Treatment Plant Spare Parts (Control Valves, Actuators, Flow meters, Pressure Transmitters, etc) for Ambo Mineral Water S.C
         </p>


        <p>     <li className='text-left mb-3 fof text-xl '> Supply and Installation of Electromechanical Equipment  </li>    Supply and Installation of Pump Stations ,

        Supply and Installation of Submersible pumps, Drainage pumps and Centrifugal pumps, Supply and Installation of Process Automation Systems (Instrumentation, Factory Automation, DCS and SCADA), Supply and Installation of Diesel Generators
        

        </p>



        <p>   <li className='text-left mb-3 fof text-xl '>  Maintenance and Rehabilitation of Industrial Control Systems</li>Maintenance of industrial control systems involves a proactive approach to keep the systems functioning optimally. Regular inspections, diagnostics, and preventive maintenance activities are performed to identify and address potential issues before they escalate into major problems. This includes tasks such as checking for firmware updates, conducting system backups, and monitoring performance metrics to ensure optimal system operation.</p>



        <p>   <li className='text-left mb-3 fof text-xl '> Supply and Installation of OEM Spare Parts for Factories </li> We supply factory spare parts for food and beverage, sugar and cement factories,Supply of Various Field Instruments,Supply of Electrical Parts</p>

      </div>



      <div className='footerFeatures3 flex flex-row absolute'>
        <FaShippingFast className=' w-24 h-20 widthHeight' />
        <FaLock className='w-14 h-16 widthHeight' />
        <BsCurrencyDollar className='w-24 h-20 widthHeight' />
        <img src={better} className="w-20 widthHeight" alt='hey' />
      </div>

      <div className='footerFeatures3TextHold fof flex flex-row absolute uppercase'>
        <p>Quality Products</p>
        <p> secure checkout </p>
        <p> royalty points </p>
        <p> easy returns </p>
      </div>

      <div className='jfHold absolute'>
        <JournalPageFooter />
      </div>


    </div>
  </div>
  )
}

export default JournalPage