// // import React from 'react';
// // import test9 from '../assets/test9.jpeg'
// // import { useState, useEffect } from 'react';
// // import axios from 'axios';

// // const AboutUs = () => {
// //   const [data, setData] = useState(null);
// //   const [isLoading, setIsLoading] = useState(true);

// //   const fetchTestimonials = () => {
// //     axios
// //       .get('https://api.synergyindustri.com/api/ourvalue-getall/')
// //       .then((response) => {
// //         setData(response.data);
// //         setIsLoading(false); // Set loading state to false when data is fetched
// //       })
// //       .catch((error) => {
// //         console.error('Error fetching testimonials:', error);
// //         setIsLoading(false); // Set loading state to false even if there's an error
// //       });
// //   };

// //   useEffect(() => {
// //     fetchTestimonials();
// //   }, []);

// //   return (
// //     <div className="bg-gray-100 py-8">
// //       <div className="container mx-auto px-4">
// //         <h1 className="text-3xl font-bold text-center mb-8">About Synergy Company</h1>
// //         <div className="flex flex-col md:flex-row">
// //           <div className="md:w-1/2">
// //             <img
// //               src={test9}
// //               alt="About Us"
// //               className="rounded-lg mb-4"
// //             />
// //           </div>
// //           <div className="md:w-1/2 md:pl-8">
// //             <h2 className="text-2xl font-semibold mb-4">Who We Are {data.title}</h2>
// //             <p className="text-gray-800 mb-4">{data.description}

// //               Synergy Company is a leading provider of innovative solutions for businesses
// //               across various industries. We specialize in helping companies achieve their
// //               goals through the power of technology and strategic partnerships.
// //             </p>
// //             <p className="text-gray-800 mb-4">
// //               With a team of dedicated professionals, we strive to deliver exceptional
// //               results and exceed our clients' expectations. Our expertise lies in
// //               software development, digital marketing, and IT consulting services.
// //             </p>
// //             <p className="text-gray-800 mb-4">
// //               At Synergy Company, we believe in the synergy of collaboration, where the
// //               combined efforts of our team and our clients create innovative and
// //               sustainable solutions that drive business growth.
// //             </p>
// //           </div>
// //         </div>
// //         <div className="mt-8">
// //           <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
// //           <p className="text-gray-800 mb-4">
// //             Our mission is to empower businesses with cutting-edge technology solutions,
// //             enabling them to thrive in the digital era. We strive to build long-term
// //             partnerships with our clients, understanding their unique needs and providing
// //             tailored solutions that drive success.
// //           </p>
// //         </div>
// //         <div className="mt-8">
// //           <h2 className="text-2xl font-semibold mb-4">Our Values</h2>
// //           <ul className="list-disc list-inside text-gray-800">
// //             <li className="mb-2">Innovation: We embrace innovation and continuously seek new ideas and approaches.</li>
// //             <li className="mb-2">Excellence: We strive for excellence in everything we do, delivering high-quality solutions.</li>
// //             <li className="mb-2">Integrity: We uphold the highest ethical standards and treat our clients with honesty and respect.</li>
// //             <li className="mb-2">Collaboration: We foster a collaborative environment, valuing teamwork and open communication.</li>
// //             <li className="mb-2">Customer Focus: We prioritize the needs of our clients, going above and beyond to ensure their satisfaction.</li>
// //           </ul>
// //         </div>
// //         <button onClick={navigator}>
// // <h1 className="text-lg font-bold mb-4 text-blue-700 hover:cursor-pointer hover:underline"> Go To Home</h1>
// // </button>
// //       </div>
// //     </div>
// //   );
// // };

// // export default AboutUs;





import React, { useState, useEffect } from 'react';
import test9 from '../assets/test9.jpeg';
import axios from 'axios';

const AboutUs = () => {
  const [data, setData] = useState([]);
  const [mission, setMission] = useState({});
  const [blogPosts, setBlogPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newValue, setNewValue] = useState({ title: '', description: '' });
  const [updatedMission, setUpdatedMission] = useState({ title: '', description: '' });
  // const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true); // New loading state
  // const isLoggedIn = localStorage.getItem('token') && localStorage.getItem('user');
  // const isLoggedIn = !!(localStorage.getItem('token') && localStorage.getItem('user'));
  
  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const fetchBlogPosts = () => {
    const token = localStorage.getItem('token');
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
  
    if (token) {
      headers.Authorization = `token ${token}`;
    }
  
    axios
      .get('https://api.synergyindustri.com/api/AboutUs-getall/', { headers })
      .then((response) => {
        setBlogPosts(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching blog posts:', error);
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   if (window.performance.navigation.type === PerformanceNavigation.TYPE_RELOAD) {
  //     window.location.href = '/';
  //   }
  // }, []);


const handleChangePicture = (postId) => {
  console.log(`Change picture clicked for blog post ${postId}`);
  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    if (imageFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Image = e.target.result;

        const title = prompt('Enter the new title:');
        const description = prompt('Enter the new description:');

        if (title && description) {
          const token = localStorage.getItem('token');
          const headers = {
            Authorization: `token ${token}`,
          };

          axios
            .put(`https://api.synergyindustri.com/api/AboutUs/${postId}/`, { image: base64Image, title, description }, { headers })
            .then((response) => {
              console.log('Image changed successfully:', response.data);
              fetchBlogPosts(); // Refresh the blog post list
            })
            .catch((error) => {
              console.error('Error changing image:', error);
            });
        }
      };
      reader.readAsDataURL(imageFile);
    }
  };

  const inputElement = document.createElement('input');
  inputElement.type = 'file';
  inputElement.accept = 'image/*';
  inputElement.addEventListener('change', handleImageChange);
  inputElement.click();
};



const handleAddImage = () => {
  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    if (imageFile) {
      const title = prompt('Enter the title:');
      const description = prompt('Enter the description:');

      if (title && description) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Image = e.target.result;

          const formData = new FormData();
          formData.append('image', base64Image);
          formData.append('title', title);
          formData.append('description', description);

          const token = localStorage.getItem('token');
          const headers = {
            Authorization: `token ${token}`,
            'Content-Type': 'multipart/form-data',
          };

          axios
            .post('https://api.synergyindustri.com/api/AboutUs/create/', formData, { headers })
            .then((response) => {
              console.log('Image added successfully:', response.data);
              fetchBlogPosts(); // Refresh the blog post list
            })
            .catch((error) => {
              console.error('Error adding image:', error);
            });
        };
        reader.readAsDataURL(imageFile);
      }
    }
  };

  const inputElement = document.createElement('input');
  inputElement.type = 'file';
  inputElement.accept = 'image/*';
  inputElement.addEventListener('change', handleImageChange);
  inputElement.click();
};


const handleDeletePost = (postId) => {
  console.log(`Delete button clicked for blog post ${postId}`);
  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `token ${token}`,
  };

  axios
    .delete(`https://api.synergyindustri.com/api/AboutUs/${postId}/delete/`, { headers })
    .then((response) => {
      console.log('Blog post deleted successfully:', response.data);
      fetchBlogPosts(); // Refresh the blog post list
    })
    .catch((error) => {
      console.error('Error deleting AboutUs post:', error);
    });
};

const handleLogout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  window.location = '/';; // Redirect to homepage
};



  const fetchBlogPosts1 = () => {
    const token = localStorage.getItem('token');
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
  
    if (token) {
      headers.Authorization = `token ${token}`;
    }
  
    axios
    .get(`https://api.synergyindustri.com/api/whoweare-getall/`, { headers })
      .then((response) => {
        setBlogPosts(response.data);
        console.log(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching blog posts:', error);
        setLoading(false);
      });
  };



  const handleChangePicture1 = (postId) => {
    console.log(`Change picture clicked for blog post ${postId}`);
    const handleImageChange = (event) => {
      const imageFile = event.target.files[0];
      if (imageFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Image = e.target.result;
  
          const title = prompt('Enter the new title:');
          const description = prompt('Enter the new description:');
  
          if (title && description) {
            const token = localStorage.getItem('token');
            const headers = {
              Authorization: `token ${token}`,
            };
  
            const formData = new FormData();
            formData.append('image', base64Image);
            formData.append('title', title);
            formData.append('description', description);
  
            axios
              .put(`https://api.synergyindustri.com/api/whoweare/${postId}/`, formData, { headers })
              .then((response) => {
                console.log('Image changed successfully:', response.data);
                fetchBlogPosts(); 
                console.log(response.data)// Refresh the blog post list
              })
              .catch((error) => {
                console.error('Error changing image:', error);  
              });
          }
        };
        reader.readAsDataURL(imageFile);
      }
    };
  
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*';
    inputElement.addEventListener('change', handleImageChange);
    inputElement.click();
  };


  const handleUpdateMission = (postId) => {
    const token = localStorage.getItem('token');
    const headers = { Authorization: `token ${token}` };
  
    axios
      .put(`https://api.synergyindustri.com/api/ourmission/${postId}/`, updatedMission, { headers })
      .then((response) => {
        console.log('Mission updated successfully:', response.data);
        // Refresh the mission data or perform any other necessary actions
      })
      .catch((error) => {
        console.error('Error updating mission:', error);
      });
  };

  const fetchTestimonials = () => {
    axios
      .get('https://api.synergyindustri.com/api/ourvalue-getall/')
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })                                                                                                                                                  
      .catch((error) => {
        console.error('Error fetching testimonials:', error);
        setIsLoading(false);
      });
  };

  const missionblog = () => {
    axios
      .get('https://api.synergyindustri.com/api/ourmission-getall/')
      .then((response) => {
        setMission(response.data);
        console.log(response.data);
        setIsLoading(false);
      })                                                                                                                                                  
      .catch((error) => {
        console.error('Error fetching testimonials:', error);
        setIsLoading(false);
      });
  };

  // useEffect(() => {
  //   fetchTestimonials();
  // }, []);

  useEffect(() => {
    fetchTestimonials();
    fetchBlogPosts();
    missionblog()
  }, []);


  const createValue = () => {
    const token = localStorage.getItem('token');
    const headers = { Authorization: `token ${token}` };

    axios
      .post('https://api.synergyindustri.com/api/ourvalue/create/', newValue, { headers })
      .then((response) => {
        setData([...data, response.data]);
        setNewValue({ title: '', description: '' });
      })
      .catch((error) => {
        console.error('Error creating value:', error);
      });
  };

  const deleteValue = (id) => {
    const token = localStorage.getItem('token');
    const headers = { Authorization: `token ${token}` };
    
    axios
      .delete(`https://api.synergyindustri.com/api/ourvalue/${id}/delete/`, { headers })
      .then(() => {
        setData(data.filter((value) => value.id !== id));
      })
      .catch((error) => {
        console.error('Error deleting value:', error);
      });
  };

  const handleInputChange = (event) => {
    setNewValue({ ...newValue, [event.target.name]: event.target.value });
  };


  const navigator = () => {
    window.location.href = '/';
  };

  const isLoggedIn = !!(localStorage.getItem('token') && localStorage.getItem('user'));

  return (
    <div className="bg-gray-100 py-8">
      <div className="container mx-auto px-4">
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            <h1 className="text-3xl font-bold text-center mb-8">About  Us</h1>

            <div className="flex flex-wrap justify-center">
            {blogPosts.map((post) => (
                <div key={post.id} className="w-full p-4 flex flex-col md:flex-row">
                <div className="w-full md:w-1/2 mb-4 md:mb-0 px-2">
                  <img src={post.image} alt={post.name} className="h-96 w-full rounded-t-lg" />
                </div>
                <div className="w-full md:w-1/2">
                  <div className="overflow-y-auto bg w-full text-justify mt-[-10px]" style={{ maxHeight: '30px' }}>
                    <p className="text-lg font-bold">{post.title}</p>
                  </div>
                  <div className="overflow-y-auto bg w-full p-1 text-justify mt-4" style={{ maxHeight: '290px' }}>
                    <h3 className="mt-2 text-gray-600">{post.description}</h3>
                  </div>
                      {isLoggedIn && (
                        <button
                          onClick={() => handleChangePicture(post.id)}
                          className="mt-2 bg-blue-500 text-sm px-2 py-1 rounded-lg text-white hover:cursor-pointer hover:bg-blue-600"
                        >
                          Edit
                        </button>
                      )}
                      {isLoggedIn && (
                        <button
                          onClick={() => handleDeletePost(post.id)}
                          className="mt-2 bg-red-500 text-sm text-white px-2 py-1 rounded-lg ml-4 hover:cursor-pointer hover:bg-red-600"
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </div>
                // </div>
            //   </div>
            ))}
            {isLoggedIn && (
              <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4">
                <div className="bg-white rounded-lg shadow-lg text-center">
                  <div className="h-48 flex items-center justify-center">
                    <button
                      onClick={handleAddImage}
                      className="bg-green-500 text-white px-4 py-2 rounded-lg"
                    >
                      Add Image
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          
    
            <div className="mt-8">
              <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
              <ul className="list-disc list-inside text-gray-800">
                {data.map((value) => (
                  <li key={value.id} className="mb-2">
                    {value.title}: {value.description}
                    {isLoggedIn && (
                    <button
                      className="text-red-500 hover:text-red-700 ml-2"
                      onClick={() => deleteValue(value.id)}
                    >
                      Delete
                    </button>
                    )}
                  </li>
                ))}
              </ul>
              {isLoggedIn && (
              <div className="mt-4">
                <input
                  type="text"
                  name="title"
                  value={newValue.title}
                  onChange={handleInputChange}
                  placeholder="Enter a new value title"
                  className="border border-gray-400 px-4 py-2 mr-2"
                />
                <input
                  type="text"
                  name="description"
                  value={newValue.description}
                  onChange={handleInputChange}
                  placeholder="Enter a new value description"
                  className="border border-gray-400 px-4 py-2 mr-2"
                />

                <button
                  onClick={createValue}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Create
                </button>

              </div>
        )}

            </div>

          </>
        )}
      </div>
    </div>
  );
};

export default AboutUs;

























































// import React, { useState, useEffect } from 'react';
// import test9 from '../assets/test9.jpeg';
// import axios from 'axios';

// const AboutUs = () => {
//   const [postId, setPostId] = useState('');
//   const [mission, setMission] = useState({});
//   const [isLoading, setIsLoading] = useState(true);
//   const [newValue, setNewValue] = useState({ title: '', description: '' });
//   const [data, setData] = useState([]);
//   const fetchTestimonials = () => {
//     axios
//       .get(`https://api.synergyindustri.com/api/ourmission-posts/${postId}/`)
//       .then((response) => {
//         setMission(response.data);
//         setIsLoading(false);
//       })
//       .catch((error) => {
//         console.error('Error fetching mission:', error);
//         setIsLoading(false);
//       });
//   };

//   useEffect(() => {
//     fetchTestimonials();
//   }, [postId]);

//   const createValue = () => {
//     const token = localStorage.getItem('token');
//     const headers = { Authorization: `token ${token}` };

//     axios
//       .post('https://api.synergyindustri.com/api/ourvalue/create/', newValue, { headers })
//       .then((response) => {
//         setData([...data, response.data]);
//         setNewValue({ title: '', description: '' });
//       })
//       .catch((error) => {
//         console.error('Error creating value:', error);
//       });
//   };

//   const deleteValue = (id) => {
//     const token = localStorage.getItem('token');
//     const headers = { Authorization: `token ${token}` };

//     axios
//       .delete(`https://api.synergyindustri.com/api/ourvalue/${id}/delete/`, { headers })
//       .then(() => {
//         setData(data.filter((value) => value.id !== id));
//       })
//       .catch((error) => {
//         console.error('Error deleting value:', error);
//       });
//   };

//   const handleInputChange = (event) => {
//     setNewValue({ ...newValue, [event.target.name]: event.target.value });
//   };

//   const navigator = () => {
//     window.location.href = '/';
//   };

//   const updateMission = () => {
//     const token = localStorage.getItem('token');
//     const headers = { Authorization: `token ${token}` };

//     axios
//       .put(`https://api.synergyindustri.com/api/ourmission/${postId}/`, mission, { headers })
//       .then((response) => {
//         setMission(response.data);
//       })
//       .catch((error) => {
//         console.error('Error updating mission:', error);
//       });
//   };

//   return (
//     <div className="bg-gray-100 py-8">
//       <div className="container mx-auto px-4">
//         {isLoading ? (
//           <p>Loading...</p>
//         ) : (
//           <>
//             <h1 className="text-3xl font-bold text-center mb-8">About Synergy Company</h1>
//             <div className="flex flex-col md:flex-row">
//               <div className="md:w-1/2">
//                 <img src={test9} alt="About Us" className="rounded-lg mb-4" />
//               </div>
//               <div className="md:w-1/2 md:pl-8">
//                 <h2 className="text-2xl font-semibold mb-4">{data[0].title}</h2>
//                 <p className="text-gray-800 mb-4">{data[0].description}</p>
//               </div>
//             </div>
//             <div className="mt-8">
//               <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
//               <input
//                 type="text"
//                 name="postId"
//                 value={postId}
//                 onChange={(e) => setPostId(e.target.value)}
//                 placeholder="Enter the postId"
//                 className="border border-gray-400 px-4 py-2 mr-2"
//               />
//               <p className="text-gray-800 mb-4">{mission.description}</p>
//               <button
//                 onClick={updateMission}
//                 className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//               >
//                 Save
//               </button>
//             </div>
//             <div className="mt-8">
//               <h2 className="text-2xl font-semibold mb-4">Our Values</h2>
//               <ul className="list-disc list-inside text-gray-800">
//                 {data.map((value) => (
//                   <li key={value.id} className="mb-2">
//                     {value.title}: {value.description}
//                     <button
//                       className="text-red-500 hover:text-red-700 ml-2"
//                       onClick={() => deleteValue(value.id)}
//                     >
//                      Delete
//                     </button>
//                   </li>
//                 ))}
//               </ul>
//               <div className="flex mt-4">
//                 <input
//                   type="text"
//                   name="title"
//                   value={newValue.title}
//                   onChange={handleInputChange}
//                   placeholder="Enter the value title"
//                   className="border border-gray-400 px-4 py-2 mr-2"
//                 />
//                 <input
//                   type="text"
//                   name="description"
//                   value={newValue.description}
//                   onChange={handleInputChange}
//                   placeholder="Enter the value description"
//                   className="border border-gray-400 px-4 py-2 mr-2"
//                 />
//                 <button
//                   onClick={createValue}
//                   className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//                 >
//                   Add Value
//                 </button>
//               </div>
//             </div>
//             <button
//               onClick={navigator}
//               className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-8 rounded"
//             >
//               Go back
//             </button>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default AboutUs;





