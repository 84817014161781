import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import car1 from "../assets/car1.png";
import car2 from "../assets/car2.png";
import car3 from "../assets/car3.png";
import { FaArrowRight } from "react-icons/fa";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true, // Enable autoplay
      autoplaySpeed: 3000, // Set autoplay speed to 3 seconds (3000 milliseconds)
    };

    return (
      <div className="carousel-contain mt-6">
        <Slider {...settings}>
          <div>
            <img src={car1} alt="Car 1" className="car-pic" />
          </div>
          <div>
            <img src={car2} alt="Car 2" className="car-pic" />
          </div>
          <div>
            <img src={car3} alt="Car 3" className="car-pic" />
          </div>
        </Slider>
      </div>
    );
  }
}