import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';

function WelcomePage() {
  const Card = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // useEffect(() => {
    //   axios
    //     // .get('https://lasttouch.meqne.com/api/Welcomesynergy-getall/')
    //     .get('http://127.0.0.1:8000/api/Welcomesynergy-getall/')
    //     .then((response) => {
    //       setBlogPosts(response.data);
    //     })
    //     .catch((error) => {
    //       console.error('Error fetching blog posts:', error);
    //     });
    // }, []);



    const fetchBlogPosts = () => {
      axios
        .get('http://127.0.0.1:8000/api/Welcomesynergy-getall/')
        .then((response) => {
          setBlogPosts(response.data);
          setIsLoading(false); // Set loading state to false when data is fetched
        })
        .catch((error) => {
          console.error('Error fetching testimonials:', error);
          setIsLoading(false); // Set loading state to false even if there's an error
        });
    };
  
    useEffect(() => {
      fetchBlogPosts();
    }, []);









    // useEffect(() => {
    //   fetchBlogPosts();
    // }, []);
  


    const handleChangePicture = (postId) => {
      console.log(`Change picture clicked for blog post ${postId}`);
      const handleImageChange = (event) => {
        const imageFile = event.target.files[0];
        if (imageFile) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const base64Image = e.target.result;
    
            const title = prompt('Enter the new title:');
            const description = prompt('Enter the new description:');
    
            if (title && description) {
              const token = localStorage.getItem('token');
              const headers = {
                Authorization: `token ${token}`,
              };
    
              axios
                .put(`http://127.0.0.1:8000/api/Welcomesynergy/${postId}/`, { image: base64Image, title, description }, { headers })
                .then((response) => {
                  console.log('Image changed successfully:', response.data);
                  fetchBlogPosts(); // Refresh the blog post list
                })
                .catch((error) => {
                  console.error('Error changing image:', error);
                });
            }
          };
          reader.readAsDataURL(imageFile);
        }
      };
    
      const inputElement = document.createElement('input');
      inputElement.type = 'file';
      inputElement.accept = 'image/*';
      inputElement.addEventListener('change', handleImageChange);
      inputElement.click();
    };


    const handleDeletePost = (postId) => {
      console.log(`Delete button clicked for blog post ${postId}`);
      const token = localStorage.getItem('token');
      const headers = {
        Authorization: `token ${token}`,
      };
    
      axios
        .delete(`http://127.0.0.1:8000/api/Welcomesynergy/${postId}/delete/`, { headers })
        .then((response) => {
          console.log('Blog post deleted successfully:', response.data);
          fetchBlogPosts(); // Refresh the blog post list
        })
        .catch((error) => {
          console.error('Error deleting blog post:', error);
        });
    };






    const handleAddImage = () => {
      const handleImageChange = (event) => {
        const imageFile = event.target.files[0];
        if (imageFile) {
          const title = prompt('Enter the title:');
          const description = prompt('Enter the description:');
    
          if (title && description) {
            const reader = new FileReader();
            reader.onload = (e) => {
              const base64Image = e.target.result;
    
              const formData = new FormData();
              formData.append('image', base64Image);
              formData.append('title', title);
              formData.append('description', description);
    
              const token = localStorage.getItem('token');
              const headers = {
                Authorization: `token ${token}`,
                'Content-Type': 'multipart/form-data',
              };
    
              axios
                .post('http://127.0.0.1:8000/api/Welcomesynergy/create/', formData, { headers })
                .then((response) => {
                  console.log('Image added successfully:', response.data);
                  fetchBlogPosts(); // Refresh the blog post list
                })
                .catch((error) => {
                  console.error('Error adding image:', error);
                });
            };
            reader.readAsDataURL(imageFile);
          }
        }
      };
    
      const inputElement = document.createElement('input');
      inputElement.type = 'file';
      inputElement.accept = 'image/*';
      inputElement.addEventListener('change', handleImageChange);
      inputElement.click();
    };




    const isLoggedIn = !!(localStorage.getItem('token') && localStorage.getItem('user'));

    return (
      <>
        {blogPosts.map((post) => (
          <div className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg hover:bg-gray-200 hover:cursor-pointer" key={post.id}>
            <img className="w-full h-48 object-cover" src={post.image} alt={post.title} />
            <div className="p-4">
              <h3 className="text-xl font-medium mb-2">{post.title}</h3>
              <p className="text-gray-600 text-justify">{post.description}</p>
              <Link to="/productlist" className="text-blue-500 inline-block mt-2">Show more</Link>
              <div className='flex flex-row justify-end'> 
              {isLoggedIn && (
                <button
                onClick={() => handleChangePicture(post.id)}
                className="mt-2 bg-blue-500 text-sm px-2 py-1 rounded-lg text-white hover:cursor-pointer hover:bg-blue-600"
              >
                Edit
              </button>
              )}
              {isLoggedIn && (
                <button
                  onClick={() => handleDeletePost(post.id)}
                  className="mt-2 bg-red-500 text-sm text-white px-2 py-1 rounded-lg ml-4 hover:cursor-pointer hover:bg-red-600"
                >
                  Delete
                </button>
              )}
              </div>
      
            </div>
          </div>
        ))}

        {isLoggedIn && (
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4">
            <div className="">
              <div className="h-48 flex items-center justify-center">
                <button onClick={handleAddImage} className="bg-green-500 text-white px-4 py-2 rounded-lg">
                  Add Image
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-3xl font-bold mb-8 text-center">WELCOME TO SYNERGY INDUSTRIAL PVT. LTD. CO.</h1>
      <p className="text-lg mb-8 text-justify px-4">
        Synergy Industrial Pvt. Ltd. Co.: Your trusted partner in electromechanical, automation, and instrumentation solutions.
        Serving Ethiopian and East African industries with expertise and excellence. Committed to delivering world-class engineering services.
      </p>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <Card />
      </div>
    </div>
  );
}

export default WelcomePage;