// import React from 'react';

// const products = [
//   {
//     id: 1,
//     name: 'Product 1',
//     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
//     image: require("C:/Users/pc/Desktop/Bring Desktop (1)/j/react-e-commerce--main/src/assets/parse5.jpg"),
//   },
//   {
//     id: 2,
//     name: 'Product 2',
//     description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
//     image: require("C:/Users/pc/Desktop/Bring Desktop (1)/j/react-e-commerce--main/src/assets/parse5.jpg"),
//   },
// ];

// const ProductList = () => {
//   const isLoggedIn = localStorage.getItem('token') && localStorage.getItem('user');

//   const handleChangePicture = (productId) => {
//     // Implement your logic to handle the change picture functionality
//     console.log(`Change picture clicked for product ${productId}`);
//   };

//   const handleAddImage = () => {
//     // Implement your logic to handle adding a new image
//     console.log('Add image clicked');
//   };

//   return (
//     <div className="flex flex-wrap justify-center">
//       {products.map(product => (
//         <div key={product.id} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4">
//           <div className="bg-white rounded-lg shadow-lg">
//             <img src={product.image} alt={product.name} className="h-48 w-full object-cover rounded-t-lg" />
//             <div className="p-4">
//               <h3 className="text-lg font-semibold">{product.name}</h3>
//               <p className="mt-2 text-gray-600">{product.description}</p>
//               {isLoggedIn && (
//                 <button
//                   onClick={() => handleChangePicture(product.id)}
//                   className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg"
//                 >
//                   Change Picture
//                 </button>
//               )}
//             </div>
//           </div>
//         </div>
//       ))}
//       {isLoggedIn && (
//         <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4">
//           <div className="bg-white rounded-lg shadow-lg text-center">
//             <div className="h-48 flex items-center justify-center">
//               <button
//                 onClick={handleAddImage}
//                 className="bg-green-500 text-white px-4 py-2 rounded-lg"
//               >
//                 Add Image
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ProductList;









// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const ProductList = () => {
//   const [products, setProducts] = useState([]);
//   const isLoggedIn = localStorage.getItem('token') && localStorage.getItem('user');

//   useEffect(() => {
//     fetchProducts();
//   }, []);

//   const fetchProducts = () => {
//     axios.get('/api/products/')
//       .then(response => {
//         setProducts(response.data);
//       })
//       .catch(error => {
//         console.error('Error fetching products:', error);
//       });
//   };

//   const handleChangePicture = (productId) => {
//     // Implement your logic to handle the change picture functionality
//     console.log(`Change picture clicked for product ${productId}`);
//     const imageFile = prompt('Enter the image URL:');
//     if (imageFile) {
//       axios.put(`/api/products/${productId}/`, { image: imageFile })
//         .then(response => {
//           console.log('Image changed successfully:', response.data);
//           fetchProducts(); // Refresh the product list
//         })
//         .catch(error => {
//           console.error('Error changing image:', error);
//         });
//     }
//   };

//   const handleAddImage = () => {
//     // Implement your logic to handle adding a new image
//     console.log('Add image clicked');
//     const imageFile = prompt('Select an image file:');
//     if (imageFile) {
//       const formData = new FormData();
//       formData.append('image', imageFile);
//       axios.post('/api/products/', formData)
//         .then(response => {
//           console.log('Image added successfully:', response.data);
//           fetchProducts(); // Refresh the product list
//         })
//         .catch(error => {
//           console.error('Error adding image:', error);
//         });
//     }
//   };

//   return (
//     <div className="flex flex-wrap justify-center">
//       {products.map(product => (
//         <div key={product.id} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4">
//           <div className="bg-white rounded-lg shadow-lg">
//             <img src={product.image} alt={product.name} className="h-48 w-full object-cover rounded-t-lg" />
//             <div className="p-4">
//               <h3 className="text-lg font-semibold">{product.name}</h3>
//               <p className="mt-2 text-gray-600">{product.description}</p>
//               {isLoggedIn && (
//                 <button
//                   onClick={() => handleChangePicture(product.id)}
//                   className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg"
//                 >
//                   Change Picture
//                 </button>
//               )}
//             </div>
//           </div>
//         </div>
//       ))}
//       {isLoggedIn && (
//         <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4">
//           <div className="bg-white rounded-lg shadow-lg text-center">
//             <div className="h-48 flex items-center justify-center">
//               <button onClick={handleAddImage} className="bg-green-500 text-white px-4 py-2 rounded-lg">
//                 Add Image
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ProductList;


























import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingLogo from './LoadingLogo';
const BlogPostList = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [loading, setLoading] = useState(true); // New loading state
    // const isLoggedIn = localStorage.getItem('token') && localStorage.getItem('user');
    const isLoggedIn = !!(localStorage.getItem('token') && localStorage.getItem('user'));
    
    useEffect(() => {
      fetchBlogPosts();
    }, []);

    const fetchBlogPosts = () => {
      const token = localStorage.getItem('token');
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
    
      if (token) {
        headers.Authorization = `token ${token}`;
      }
    
      axios
        .get('https://api.synergyindustri.com/api/blog-posts-getall/', { headers })
        .then((response) => {
          setBlogPosts(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching blog posts:', error);
          setLoading(false);
        });
    };

    // useEffect(() => {
    //   if (window.performance.navigation.type === PerformanceNavigation.TYPE_RELOAD) {
    //     window.location.href = '/';
    //   }
    // }, []);
  

  const handleChangePicture = (postId) => {
    console.log(`Change picture clicked for blog post ${postId}`);
    const handleImageChange = (event) => {
      const imageFile = event.target.files[0];
      if (imageFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Image = e.target.result;
  
          const title = prompt('Enter the new title:');
          const description = prompt('Enter the new description:');
  
          if (title && description) {
            const token = localStorage.getItem('token');
            const headers = {
              Authorization: `token ${token}`,
            };
  
            axios
              .put(`https://api.synergyindustri.com/api/blog/${postId}/`, { image: base64Image, title, description }, { headers })
              .then((response) => {
                console.log('Image changed successfully:', response.data);
                fetchBlogPosts(); // Refresh the blog post list
              })
              .catch((error) => {
                console.error('Error changing image:', error);
              });
          }
        };
        reader.readAsDataURL(imageFile);
      }
    };
  
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*';
    inputElement.addEventListener('change', handleImageChange);
    inputElement.click();
  };


  
  const handleAddImage = () => {
    const handleImageChange = (event) => {
      const imageFile = event.target.files[0];
      if (imageFile) {
        const title = prompt('Enter the title:');
        const description = prompt('Enter the description:');
  
        if (title && description) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const base64Image = e.target.result;
  
            const formData = new FormData();
            formData.append('image', base64Image);
            formData.append('title', title);
            formData.append('description', description);
  
            const token = localStorage.getItem('token');
            const headers = {
              Authorization: `token ${token}`,
              'Content-Type': 'multipart/form-data',
            };
  
            axios
              .post('https://api.synergyindustri.com/api/blog/create/', formData, { headers })
              .then((response) => {
                console.log('Image added successfully:', response.data);
                fetchBlogPosts(); // Refresh the blog post list
              })
              .catch((error) => {
                console.error('Error adding image:', error);
              });
          };
          reader.readAsDataURL(imageFile);
        }
      }
    };
  
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*';
    inputElement.addEventListener('change', handleImageChange);
    inputElement.click();
  };


  const handleDeletePost = (postId) => {
    console.log(`Delete button clicked for blog post ${postId}`);
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `token ${token}`,
    };
  
    axios
      .delete(`https://api.synergyindustri.com/api/blog/${postId}/delete/`, { headers })
      .then((response) => {
        console.log('Blog post deleted successfully:', response.data);
        fetchBlogPosts(); // Refresh the blog post list
      })
      .catch((error) => {
        console.error('Error deleting blog post:', error);
      });
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location = '/';; // Redirect to homepage
  };


  
  return (
    <div className="outer-card shadow-lg rounded-lg bg-gray-200 mx-auto w-11/12 mt-4">
    <h1 className='flex flex-row justify-center items-center font-semibold p-3 py-10 text-4xl mt-20 text-center'>Some of Our Products</h1>
    {loading ? ( // Render the loading spinner if loading is true
    <div> 
   
    <LoadingLogo />
    <h1 className='flex flex-row justify-center items-center text-center text-synergy-blue animate-pulse font-bold'>
    Please Wait Fetching Products From the Database
  </h1>
    </div>
        // <LoadingLogo />
      ) : (
    <>
    <div className="flex flex-wrap justify-center ">
      {blogPosts.map((post) => (
        <div key={post.id} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4">
          <div className="bg-white rounded-lg shadow-lg">
            <img src={post.image} alt={post.name} className="h-48 w-full rounded-t-lg " />
            <div className="p-4">

            <div className='overflow-y-auto bg w-full' style={{ maxHeight: '30px' }}>
              <p className="text-lg font-semibold">{post.title}</p>
            </div>
              <div className='overflow-y-auto bg w-full p-1' style={{ maxHeight: '70px' }}>
              <h3 className="mt-2 text-gray-600">{post.description}</h3>
            </div>
              
              
              {isLoggedIn && (
                <button
                onClick={() => handleChangePicture(post.id)}
                className="mt-2 bg-blue-500 text-sm px-2 py-1 rounded-lg text-white hover:cursor-pointer hover:bg-blue-600"
              >
                Edit
              </button>
              )}

              {isLoggedIn && (
                <button
                  onClick={() => handleDeletePost(post.id)}
                  className="mt-2 bg-red-500 text-sm text-white px-2 py-1 rounded-lg ml-4 hover:cursor-pointer hover:bg-red-600"
                >
                  Delete
                </button>
              )}
            </div>
          </div>
        </div>
      ))}
      {isLoggedIn && (
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4">
          <div className="bg-white rounded-lg shadow-lg text-center">
            <div className="h-48 flex items-center justify-center">
              <button onClick={handleAddImage} className="bg-green-500 text-white px-4 py-2 rounded-lg">
                Add Image
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
    )}
    </div>
  );
};

export default BlogPostList;
































































































































































































































































































// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const BlogPostList = () => {
//     const [blogPosts, setBlogPosts] = useState([]);
    
//     useEffect(() => {
//       fetchBlogPosts();
//     }, []);

//     const fetchBlogPosts = () => {
//       const token = localStorage.getItem('token');
//       const headers = {
//         'Content-Type': 'multipart/form-data',
//       };
//       axios
//         .get('https://api.synergyindustri.com/api/blog/')
//         .then((response) => {
//           setBlogPosts(response.data);
//         })
//         .catch((error) => {
//           console.error('Error fetching blog posts:', error);
//         });
//     };


  
//   return (
//     <div className="shadow-lg rounded-lg bg-gray-200 mx-auto w-11/12 mt-4">
//     <h1 className='flex flex-row justify-center items-center font-semibold p-3 py-10 text-4xl mt-20 text-center'>Some of Our Products</h1>
//     <>
//     <div className="flex flex-wrap justify-center ">
//       {blogPosts.map((post) => (
//         <div key={post.id} className="w-full lg:w-1/4 p-4">
//           <div className="bg-white rounded-lg shadow-lg">
//             <img src={post.image} alt={post.name} className="h-48 w-full rounded-t-lg " />
//             <div className="p-4">

//             <div className='overflow-y-auto bg w-full'>
//               <p className="text-lg font-semibold">{post.title}</p>
//             </div>
//               <div className='overflow-y-auto bg w-full p-1'>
//               <h3 className="mt-2 text-gray-600">{post.description}</h3>
//             </div>
//             </div>
//           </div>
//         </div>
//       ))}
  
//     </div>
//     </>
//     )}
//     </div>
//   );
// };

// export default BlogPostList;


























