import React, { useState } from 'react';
import { Transition } from '@headlessui/react';

const CardComponent = () => {
  const [showMore, setShowMore] = useState(false);
  const [showMore1, setShowMore1] = useState(false);
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };


  const toggleShowMore1 = () => {
    setShowMore1(!showMore1);
  };




  return (
    <div className="flex justify-center items-center h-screen">
      <div className=" flex flex-row gap-6">
        <div className="bg-yellow-200 rounded-lg overflow-hidden w-full">
          <div className="p-4">
            <div className="flex">
              <div className="w-1/2">
                <div className="p-4">
                  <h2 className="text-xl font-bold mb-2">Card 1</h2>
                  <p className="text-gray-700 mb-4">
                    This is the description for Card 1.
                  </p>
                </div>
              </div>
              <div className="w-1/2">
                <div className="relative">
                  <img
                    src="path_to_your_image1.jpg"
                    alt="Card Image 1"
                    className="h-64 w-full object-cover transition-opacity duration-500"
                  />
                  <div
                    className={`absolute inset-0 bg-black bg-opacity-50 transition-opacity duration-500 ${
                      showMore ? 'opacity-0' : 'opacity-100'
                    }`}
                  ></div>
                </div>
              </div>
            </div>
            <Transition
              show={showMore}
              enter="transition-opacity duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {(ref) => (
                <div className="bg-white shadow-lg rounded-lg overflow-hidden mt-4">
                  <div className="flex">
                    <div className="w-1/2">
                      <div className="relative">
                        <img
                          src="path_to_your_image2.jpg"
                          alt="Card Image 2"
                          className="h-64 w-full object-cover transition-opacity duration-500"
                        />
                        <div
                          className={`absolute inset-0 bg-black bg-opacity-50 transition-opacity duration-500 ${
                            showMore ? 'opacity-0' : 'opacity-100'
                          }`}
                        ></div>
                      </div>
                    </div>
                    <div className="w-1/2">
                      <div className="p-4">
                        <h2 className="text-xl font-bold mb-2">Card 2</h2>
                        <p className="text-gray-700 mb-4">
                          This is the description for Card 2.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Transition>
            <div className="flex justify-center">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
                onClick={toggleShowMore}
              >
                {showMore ? 'Show Less' : 'Show More'}
              </button>
            </div>
          </div>
        </div>
        <div className="bg-yellow-200 rounded-lg overflow-hidden w-full">
        <div className="p-4">
          <div className="flex">
            <div className="w-1/2">
              <div className="p-4">
                <h2 className="text-xl font-bold mb-2">Card 1</h2>
                <p className="text-gray-700 mb-4">
                  This is the description for Card 1.
                </p>
              </div>
            </div>
            <div className="w-1/2">
              <div className="relative">
                <img
                  src="path_to_your_image1.jpg"
                  alt="Card Image 1"
                  className="h-64 w-full object-cover transition-opacity duration-500"
                />
                <div
                  className={`absolute inset-0 bg-black bg-opacity-50 transition-opacity duration-500 ${
                    showMore1 ? 'opacity-0' : 'opacity-100'
                  }`}
                ></div>
              </div>
            </div>
          </div>
          <Transition
            show={showMore1}
            enter="transition-opacity duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {(ref) => (
              <div className="bg-white shadow-lg rounded-lg overflow-hidden mt-4">
                <div className="flex">
                  <div className="w-1/2">
                    <div className="relative">
                      <img
                        src="path_to_your_image2.jpg"
                        alt="Card Image 2"
                        className="h-64 w-full object-cover transition-opacity duration-500"
                      />
                      <div
                        className={`absolute inset-0 bg-black bg-opacity-50 transition-opacity duration-500 ${
                          showMore1 ? 'opacity-0' : 'opacity-100'
                        }`}
                      ></div>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <div className="p-4">
                      <h2 className="text-xl font-bold mb-2">Card 2</h2>
                      <p className="text-gray-700 mb-4">
                        This is the description for Card 2.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Transition>
          <div className="flex justify-center">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
              onClick={toggleShowMore1}
            >
              {showMore ? 'Show Less' : 'Show More'}
            </button>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default CardComponent;