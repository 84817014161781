import React from 'react'
import "../styles/Navbar.css";
import logo from "../assets/cara.png";
import { useState } from 'react';
import BestSellers from './BestSellers';
import GiftSets from './GiftSets';
import Body from './Body';
import { FaShoppingBag } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {BsTelephonePlusFill} from "react-icons/bs";
import NavAvatar from './NavAvatar';
// import "./Nav.css";

const NavBar = () => {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);


    const showHandler = () => {
        setShow(true)
        setShow2(false)
        setShow3(false)
        setShow4(false)

    }

    const showHandler2 = () => {
        setShow2(true)
        setShow(false)
        setShow3(false)
        setShow4(false)

    }

    const showHandler3 = () => {
        setShow3(true)
        setShow(false)
        setShow2(false)
        setShow4(false)
    }

    const showHandler4 = () => {
        setShow4(true)
        setShow(false)
        setShow2(false)
        setShow3(false)

    }


    const dontShowHandler = () => {
        setShow(false)
        setShow2(false)
        setShow3(false)
        setShow4(false)


    }
    const isLoggedIn = !!(localStorage.getItem('token') && localStorage.getItem('user'));


    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location = '/';; // Redirect to homepage
      };



    const [dropdownVisible, setDropdownVisible] = useState(false);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
      };



    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');


    // {user && token ? (
    //     <>
    //       <p>{user.username}</p> {/* Access the username */}
    //       <p>{user.email}</p> {/* Access the email */}
    //     </>
    //   ) : (
    //     // The user is not logged in
    //     <p>User is not logged in</p>
    //   )}

const handlepasswordchange = () => {
    window.location.href = "/changepassword";
}




    return (
        <div>
  


            <header class="banner" role="banner">

                <nav class="navbar" role="navigation" aria-label="menu">

                    <Link to="/">
                      <img src={logo} className=" w-24 h-auto hover:cursor-pointer rounded-lg ml-20" />
                    </Link>
                



                    <ul class="menuNav">
                    <li class="dropdown nav-link nav-link-fade-up transition-all duration-700 hover:cursor-pointer" style={{ marginRight: '-18px' }} >
                    <Link to="/">
                    <h4 style={{ fontSize: '19px' }}>
                    Home
                    </h4>
                    </Link>

                    </li >
                        <li class="dropdown nav-link nav-link-fade-up transition-all duration-700" onMouseOver={showHandler} style={{ marginRight: '-18px',fontSize: '19px' }} >
                        <Link to="/productlist">
                        <h4 style={{ fontSize: '19px' }}>Products</h4>
                      </Link>
                            {show && <div>
                                <ul class="dropdown-nav" onMouseLeave={dontShowHandler} >

                                    <BestSellers >
                                    
                                     </BestSellers>

                                </ul>

                            </div>}

                        </li >
                  


                        <li class="dropdown nav-link nav-link-fade-up hover:cursor-pointer" onMouseOver={showHandler3} style={{ marginRight: '-18px' }} >
                            Services

                            

                            {show3 && <ul class="dropdown-nav dropdown-nav3" onMouseLeave={dontShowHandler}>
                                <Body />


                                
                            </ul>}

                        </li>
                        <li class="dropdown nav-link nav-link-fade-up transition-all duration-700 hover:cursor-pointer"  style={{ marginRight: '-18px' }} >
                        <Link to="/TestimonialSection">
                        <h4>
                   Testimonials
                        </h4>
                        </Link>

                        </li >

                        <li class="dropdown nav-link nav-link-fade-up transition-all duration-700"  >

                        <Link to="/contactus">
                        <h4>
                        Contact-Us
                        </h4>
                        </Link>

                        </li >


                 
                          

                        <li class="dropdown nav-link  transition-all duration-700"  >

                        <Link to="/">
                        {user && token ? (
                           
                            <div className='absolute right-[-200px] top-2'>
                            <NavAvatar/>
                            </div>
                          ) : (
                            // The user is not logged in
                            
                            <div className='absolute top-0 right-[-140px]'>
                            
                            <Link to="/login">
                            
                            <button
                            style={{ position: 'absolute', top: '10px', right: '[-45px]' }}
                            className="bg-synergy-orange text-white px-4 py-1 rounded-lg fixed"
                          >
                            login
                          </button>
                            </Link>
                            
                        
                            
                            </div>
                      
                          )}



                          
                        
                        </Link>

                   



                        </li >



                    </ul>

             
                
           
                </nav >
            </header >



        </div >
    )
}

export default NavBar







/*    



    


             <div class="container">
                <div class="dropdown" onMouseOver={showHandler}>
                    <button class="dropbtn">Dropdown</button>
                    <div class="dropdown-content" onMouseLeave={dontShowHandler}>
                     {show && <BestSellers /> }
                    </div>
                </div>

                <div class="dropdown" onMouseOver={showHandler2}>
                    <button class="dropbtn">Dropdown</button>
                    <div class="dropdown-content" onMouseLeave={dontShowHandler}>
                    {show2 && <GiftSets /> }
                    </div>
                </div>


                <div class="dropdown">
                    <button class="dropbtn">Dropdown</button>
                    <div class="dropdown-content">
                        Link 1
                        Link 2
                        Link 3
                    </div>
                </div>


                <a href="#news">Link</a>
            </div>

            <h3>Dropdown Menu inside a Navigation Bar</h3>
            <p>Hover over the "Dropdown" link to see the dropdown menu.</p>













*/







// this is the full code 

// import React from 'react'
// import "../styles/Navbar.css";
// import logo from "../assets/cara.png";
// import { useState } from 'react';
// import BestSellers from './BestSellers';
// import GiftSets from './GiftSets';
// import Body from './Body';
// import { FaShoppingBag } from "react-icons/fa";
// import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import {BsTelephonePlusFill} from "react-icons/bs"

// const NavBar = () => {
//     const [show, setShow] = useState(false);
//     const [show2, setShow2] = useState(false);
//     const [show3, setShow3] = useState(false);
//     const [show4, setShow4] = useState(false);


//     const showHandler = () => {
//         setShow(true)
//         setShow2(false)
//         setShow3(false)
//         setShow4(false)

//     }

//     const showHandler2 = () => {
//         setShow2(true)
//         setShow(false)
//         setShow3(false)
//         setShow4(false)

//     }

//     const showHandler3 = () => {
//         setShow3(true)
//         setShow(false)
//         setShow2(false)
//         setShow4(false)
//     }

//     const showHandler4 = () => {
//         setShow4(true)
//         setShow(false)
//         setShow2(false)
//         setShow3(false)

//     }


//     const dontShowHandler = () => {
//         setShow(false)
//         setShow2(false)
//         setShow3(false)
//         setShow4(false)


//     }
//     const isLoggedIn = !!(localStorage.getItem('token') && localStorage.getItem('user'));


//     const handleLogout = () => {
//         localStorage.removeItem('token');
//         localStorage.removeItem('user');
//         window.location = '/';; // Redirect to homepage
//       };



//     const [dropdownVisible, setDropdownVisible] = useState(false);

//     const toggleDropdown = () => {
//         setDropdownVisible(!dropdownVisible);
//       };



//     const user = JSON.parse(localStorage.getItem('user'));
//     const token = localStorage.getItem('token');


//     // {user && token ? (
//     //     <>
//     //       <p>{user.username}</p> {/* Access the username */}
//     //       <p>{user.email}</p> {/* Access the email */}
//     //     </>
//     //   ) : (
//     //     // The user is not logged in
//     //     <p>User is not logged in</p>
//     //   )}

// const handlepasswordchange = () => {
//     window.location.href = "/changepassword";
// }




//     return (
//         <div>
  


//             <header class="banner" role="banner">

//                 <nav class="navbar" role="navigation" aria-label="menu">

//                     <Link to="/">
//                       <img src={logo} className=" w-24 h-auto hover:cursor-pointer rounded-lg ml-20" />
//                     </Link>
                



//                     <ul class="menuNav">
//                     <li class="dropdown nav-link nav-link-fade-up transition-all duration-700 hover:cursor-pointer" style={{ marginRight: '-18px' }} >
//                     <Link to="/">
//                     <h4 style={{ fontSize: '19px' }}>
//                     Home
//                     </h4>
//                     </Link>

//                     </li >
//                         <li class="dropdown nav-link nav-link-fade-up transition-all duration-700" onMouseOver={showHandler} style={{ marginRight: '-18px',fontSize: '19px' }} >
//                         <Link to="/productlist">
//                         <h4 style={{ fontSize: '19px' }}>Products</h4>
//                       </Link>
//                             {show && <div>
//                                 <ul class="dropdown-nav" onMouseLeave={dontShowHandler} >

//                                     <BestSellers >
                                    
//                                      </BestSellers>

//                                 </ul>

//                             </div>}

//                         </li >
                  
//               <li class="dropdown nav-link nav-link-fade-up" onMouseOver={showHandler2} style={{ marginRight: '-18px' }} >
//                             Services
//                             {show2 && <ul class="dropdown-nav dropdown-nav2" onMouseLeave={dontShowHandler}>
//                                 <GiftSets />
//                             </ul>}

//                         </li>


//                         <li class="dropdown nav-link nav-link-fade-up" onMouseOver={showHandler3} style={{ marginRight: '-18px' }} >
//                             Industrials

                            

//                             {show3 && <ul class="dropdown-nav dropdown-nav3" onMouseLeave={dontShowHandler}>
//                                 <Body />


                                
//                             </ul>}

//                         </li>
//                         <li class="dropdown nav-link nav-link-fade-up transition-all duration-700"  style={{ marginRight: '-18px' }} >
//                         <h4>
//                    Testimonials
//                         </h4>

//                         </li >

//                         <li class="dropdown nav-link nav-link-fade-up transition-all duration-700"  >

//                         <Link to="/contactus">
//                         <h4>
//                         Contact-Us
//                         </h4>
//                         </Link>

//                         </li >


                 
                          

//                         <li class="dropdown nav-link  transition-all duration-700"  >

//                         <Link to="/">
//                         {user && token ? (
//                             <>
//                               <div className='text-white bg-blue-600 p-2 text-lg rounded-lg hover:bg-blue-300 hover:cursor-pointer '
//                                 onClick={toggleDropdown} 
//                                 onBlur={() => setDropdownVisible(false)}
//                                 tabIndex="0" // Required for onBlur to work
//                                 // style={{ 
//                                 // //   border: '1px solid black', 
//                                 //   padding: '10px', 
//                                 //   borderRadius: '10px', 
//                                 //   backgroundColor: 'green', 
//                                 //   color: 'black', 
//                                 //   text: 'white',
//                                 //   fontSize: '12px' 
//                                 // }}
//                               >

//                                Hi,{user.username}
//                                 {dropdownVisible && (
//                                   <div style={{ 
//                                     position: 'absolute', 
//                                     backgroundColor: 'white', 
//                                     // border: '1px solid black', 
//                                     backgroundColor: '#f0f0f0',
//                                     borderRadius: '5px', 
//                                     marginTop: '20px' 
//                                   }}>
//                                     <p className='bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-400 text-xs' onClick={handlepasswordchange}>Change Password</p>
//                                     <p className='bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-400 mt-2' onClick={handleLogout}>Logout</p>
//                                   </div>
//                                 )}
//                               </div>
//                             </>
//                           ) : (
//                             // The user is not logged in
//                             <Link to="/login">
//                             <button
//                             style={{ position: 'absolute', top: '10px', right: '[-5px]' }}
//                             className="bg-green-600 text-white px-4 py-1 rounded-lg"
//                           >
//                             login
//                           </button>
//                             </Link>
//                           )}



                          
                        
//                         </Link>

                   



//                         </li >



//                     </ul>

             
                
           
//                 </nav >
//             </header >



//         </div >
//     )
// }

// export default NavBar







// /*    



    


//              <div class="container">
//                 <div class="dropdown" onMouseOver={showHandler}>
//                     <button class="dropbtn">Dropdown</button>
//                     <div class="dropdown-content" onMouseLeave={dontShowHandler}>
//                      {show && <BestSellers /> }
//                     </div>
//                 </div>

//                 <div class="dropdown" onMouseOver={showHandler2}>
//                     <button class="dropbtn">Dropdown</button>
//                     <div class="dropdown-content" onMouseLeave={dontShowHandler}>
//                     {show2 && <GiftSets /> }
//                     </div>
//                 </div>


//                 <div class="dropdown">
//                     <button class="dropbtn">Dropdown</button>
//                     <div class="dropdown-content">
//                         Link 1
//                         Link 2
//                         Link 3
//                     </div>
//                 </div>


//                 <a href="#news">Link</a>
//             </div>

//             <h3>Dropdown Menu inside a Navigation Bar</h3>
//             <p>Hover over the "Dropdown" link to see the dropdown menu.</p>













// */
























































































































// new nav bar code




// import React, { useState } from 'react';

// const Navbarr = () => {
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

//   const toggleMobileMenu = () => {
//     setIsMobileMenuOpen(!isMobileMenuOpen);
//   };

//   return (
//     <nav className="bg-gray-800">
//       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//         <div className="flex items-center justify-between h-16">
//           <div className="flex-shrink-0">
//             <img className="h-8 w-8" src="/logo.png" alt="Logo" />
//           </div>
//           <div className="hidden sm:block">
//             <div className="ml-10 flex items-baseline space-x-4">
//               <a href="#home" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Home</a>
//               <a href="#products" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Products</a>
//               <a href="#services" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Services</a>
//               <a href="#testimonials" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Testimonials</a>
//               <a href="#contact" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Contact Us</a>
//             </div>
//           </div>
//           <div className="hidden sm:block">
//             <div className="ml-4 flex items-center md:ml-6">
//               <a href="#login" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Login</a>
//             </div>
//           </div>
//           <div className="-mr-2 flex sm:hidden">
//             <button type="button" onClick={toggleMobileMenu} className="text-gray-300 hover:bg-gray-700 hover:text-white px-2 py-2 rounded-md inline-flex items-center justify-center focus:outline-none" aria-controls="mobile-menu" aria-expanded={isMobileMenuOpen ? 'true' : 'false'}>
//               <span className="sr-only">Open main menu</span>
//               <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
//               </svg>
//               <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
//               </svg>
//             </button>
//           </div>
//         </div>
//       </div>

//       <div className={`sm:hidden ${isMobileMenuOpen ? 'block' : 'hidden'}`} id="mobile-menu">
//         <div className="px-2 pt-2 pb-3 space-y-1">
//           <a href="#home" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Home</a>
//           <a href="#products" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Products</a>
//           <a href="#services" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Services</a>
//           <a href="#testimonials" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Testimonials</a>
//           <a href="#contact" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Contact Us</a>
//           <a href="#login" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Login</a>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbarr;