import React, { useState } from 'react';

const EmployeeSendMessage = ({ onSendMessage }) => {
  const [message, setMessage] = useState('');

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = () => {
    onSendMessage(message);
    setMessage('');
  };

  return (
    <div>
      <h2>Send Message</h2>
      <textarea
        className="w-full h-32 p-2"
        placeholder="Enter your message..."
        value={message}
        onChange={handleMessageChange}
      ></textarea>
      <button
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mt-2"
        onClick={handleSendMessage}
      >
        Send
      </button>
    </div>
  );
};

export default EmployeeSendMessage;
