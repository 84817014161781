import logo from "../assets/Droga.jpg";
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Drogapharma
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function SignIn() {
  // const [errorMessage, setErrorMessage] = useState('');
  // const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSignIn = async () => {
    setIsLoading(true); // Start loading spinner

    try {
      const response = await axios.post('http://127.0.0.1:8000/api/login/', {
        username,
        password,
      });

      if (response.status === 200) {
        const token = response.data.token;
        localStorage.setItem("token", token);
        console.log("Authentication token:", token);
        const user = response.data.user;
        localStorage.setItem("user", JSON.stringify(user));
        navigate('/');
        const role = response.data.role;
        localStorage.setItem("role", JSON.stringify(role));
        setErrorMessage('');
      } else {
        setIsLoading(false); // Stop loading spinner
        setErrorMessage('Bad request. Please check your login credentials.');
      }
    } catch (error) {
      setIsLoading(false); // Stop loading spinner
      setErrorMessage('Failed to log in. Please try again later.');
      console.error(error);
    }
  };








  // const handleSignIn = async (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   const username = data.get('username');
  //   const password = data.get('password');

  //   try {
  //     const response = await axios.post('http://127.0.0.1:8000/api/login/', {
  //       username,
  //       password,
  //     });
  //     console.log(response);

  //     if (response.status === 200) {
  //       const { user, token, role } = response.data;
  //       localStorage.setItem('user', JSON.stringify(user));
  //       localStorage.setItem('token', token);
  //       localStorage.setItem('role', JSON.stringify(role));
  //       axios.defaults.headers.common['Authorization'] = `token ${token}`; // Set the token in the request headers
  //       navigate('/');
  //       setErrorMessage('');
  //     } else {
  //       setIsLoading(false); // Stop loading spinner
  //       setErrorMessage('Bad request. Please check your login credentials.');
  //     }
  //   } catch (error) {
  //     setIsLoading(false); // Stop loading spinner
  //     setErrorMessage('Failed to log in. Please try again later.');
  //     console.error(error);
  //   }
  // };

  // Rest of the component code...

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img style={{ height: '115px' }} className="mx-auto w-auto" src={logo} alt="My Image" />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              InputLabelProps={{ style: { marginTop: '-0.7rem' } }} // Move the label
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              InputLabelProps={{ style: { marginTop: '-0.7rem' } }}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleSignIn} >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}