import React from 'react';
import linkedin from '../assets/linkedin.png';
import whatsapp1 from '../assets/whatsapp1.png';
import twitter from '../assets/twitter.png';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';



// <div>
//   <div>
//     <iframe
//       title="Google Maps"
//       src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3111.1348749829234!2d39.26541341571295!3d9.600644792039018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1632bb8b5b8db8a3%3A0x37e4a1d7e7b6bb1e!2sEthio%20China%20Street%2C%20Dire%20Dawa%20Bldg.%2C%20Addis%20Ababa%2C%20Ethiopia!5e0!3m2!1sen!2sus!4v1639999999999!5m2!1sen!2sus"
//       width="100%"
//       height="100%"
//       frameBorder="0"
//       style={{ border: 0 }}
//       allowFullScreen
//       loading="eager"
//     ></iframe>
//   </div>
// </div>










const ContactUs = () => {
  useEffect(() => {
    if (window.performance.navigation.type === PerformanceNavigation.TYPE_RELOAD) {
      window.location.href = '/';
    }
  }, []);

  return (
    <div className="container mx-auto px-4 py-4">
      <div className="max-w-9xl mx-auto bg-gray-100 rounded-lg shadow-md overflow-hidden">
        <div className="p-6">
          <div className="flex flex-row justify-between">
            <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
          </div>

          <p className="mb-6">
            We would love to hear from you. Please reach out to us using the contact information below:
          </p>
          <div className="flex flex-col md:flex-row">
            <div className="md:w-1/2">
              <div className="rounded-lg bg-white shadow-md p-5 w-full md:w-2/3 hover:bg-gray-300 hover:cursor-pointer">
                <div className="flex flex-col">
                  <div>
                    <h3 className="text-lg font-bold mb-2">Office Address</h3>
                    <p>Ethio China Street, Dire Dawa Bldg. 8th Floor</p>
                    <p>Addis Ababa, Ethiopia</p>
                  </div>

                  <div>
                    <div>
                      <iframe
                        title="Google Maps"
                        src="https://www.google.com/maps?q=Ethio+China+Street,+Dire+Dawa+Bldg.,+Addis+Ababa,+Ethiopia&output=embed"
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="eager"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 md:mt-0 mt-8">
              <div className="rounded-lg bg-white shadow-md p-4 w-full md:w-2/3 hover:cursor-pointer">
                <div className="flex flex-col">
                  <div className="flex flex-col py-4">
                    <div className="bg-white hover:bg-gray-300 p-1 rounded-lg">
                      <p>
                        <h3 className="text-lg font-bold mb-0">Email</h3>
                        <a href="mailto:info@synerindu.com" className="text-blue-700">
                          info@synerindu.com
                        </a>
                      </p>
                    </div>
                    <div className="bg-white hover:bg-gray-300 p-1 rounded-lg">
                      <h3 className="text-lg font-bold mb-0">Phone</h3>
                      <Link to="tel:+251922222232">+251-922-22-22-32</Link>
                    </div>

                    <div className="mt-3 bg-white hover:bg-gray-300 p-1 rounded-lg">
                      <h2 className="text-xl font-bold mb-2">Social Media</h2>
                      <div className="flex space-x-4">
                        <a href="https://wa.me/251911364571" className="text-blue-500 hover:text-blue-700">
                          <img src={whatsapp1} className="w-9 h-9" alt="WhatsApp" />
                        </a>
                        <a href="https://www.linkedin.com/company/synergy-industrial-plc/" className="text-blue-500 hover:text-blue-700">
                          <img src={linkedin} className="w-9 h-9" alt="LinkedIn" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8"></div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;


// import React, { useEffect } from 'react';
// import linkedin from '../assets/linkedin.png';
// import whatsapp1 from '../assets/whatsapp1.png';
// import twitter from '../assets/twitter.png';
// import { Link } from 'react-router-dom';

// const ContactUs = () => {
//   useEffect(() => {
//     const handleBeforeUnload = (event) => {
//       event.preventDefault();
//       // Redirect to the home page
//       window.location.href = '/';
//     };

//     window.addEventListener('beforeunload', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, []);

//   return (
//     <div className="container mx-auto px-4 py-4">
//       <div className="max-w-9xl mx-auto bg-gray-100 rounded-lg shadow-md overflow-hidden">
//         <div className="p-6">
//           <div className="flex flex-row justify-between">
//             <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
//             <button onClick={navigator}>
//               <h1 className="text-lg font-bold mb-4 text-blue-700 hover:cursor-pointer hover:underline">
//                 Go To Home
//               </h1>
//             </button>
//           </div>

//           <p className="mb-6">
//             We would love to hear from you. Please reach out to us using the contact information below:
//           </p>
//           <div className="flex flex-col md:flex-row">
//             <div className="md:w-1/2">
//               <div className="rounded-lg bg-white shadow-md p-4 w-2/3 hover:bg-gray-300 hover:cursor-pointer">
//                 <div className='flex flex-col'>
                
//                 <div>
//                 <h3 className="text-lg font-bold mb-2">Office Address</h3>
//                 <p>Ethio China Street, Dire Dawa Bldg. 8th Floor</p>
//                 <p>Addis Ababa, Ethiopia</p>
//                 </div>

//                 <div>
//                 <div>
//                 <iframe
//                 title="Google Maps"
//                 src="https://www.google.com/maps?q=Ethio+China+Street,+Dire+Dawa+Bldg.,+Addis+Ababa,+Ethiopia&output=embed"
//                 width="100%"
//                 height="100%"
//                 frameBorder="0"
//                 style={{ border: 0 }}
//                 allowFullScreen
//                 loading="eager  "
//               ></iframe>
//                 </div>
//                 </div>
//                 </div>
           
            



//               </div>
//             </div>
//             <div className="md:w-1/2 md:mt-0 mt-8">
//               <div className="rounded-lg bg-white shadow-md p-4 w-2/3 hover:cursor-pointer">
//               <div className='flex flex-col'>
//               <div className='flex flex-col py-4'>



         

//               <div className='bg-white hover:bg-gray-300 p-4 rounded-lg'>
//                 <h3 className="text-lg font-bold mb-2">Phone</h3>
//                 <p>
//                   <Link to="tel:+251953852606">+251 953 852 606</Link> or{' '}
//                   <Link to="tel:+251911549664">+251 911 54 96 64</Link>
//                 </p>
//               </div>
             
//               <div className="mt-3 bg-white hover:bg-gray-300 p-4 rounded-lg">
      
//               <h2 className="text-xl font-bold mb-2">Social Media</h2>
//               <div className="flex space-x-4">
//                 <a href="#" className="text-blue-500 hover:text-blue-700">
//                   <img src={whatsapp1} className="w-9 h-9" alt="WhatsApp" />
//                 </a>
//                 <a href="#" className="text-blue-500 hover:text-blue-700">
//                   <img src={linkedin} className="w-9 h-9" alt="LinkedIn" />
//                 </a>
//                 <a href="#" className="text-blue-500 hover:text-blue-700">
//                   <img src={twitter} className="w-9 h-9" alt="Twitter" />
//                 </a>
//               </div>
//             </div>


//               </div>
         

//               <div>
              
//               </div>
              
             
              
//               </div>
              
//               </div>
//             </div>
//           </div>
//           <div className="mt-8">
            
//           <div className="rounded-lg bg-white shadow-md p-4 w-1/3 hover:bg-gray-300 hover:cursor-pointer">
//           <h3 className="text-lg font-bold mb-2">Email</h3>
//           <a href="mailto:info@synerindu.com" className='text-blue-700'>info@synerindu.com</a>
//         </div>
//           </div>


//         </div>
//       </div>
//     </div>
//   );
// };

// export default ContactUs;