import React from 'react'
import { FaShoppingBag } from "react-icons/fa";
import logo from "../assets/cara.png";
import "../styles/Navbar.css";
import { Link } from 'react-router-dom';
import { useState } from 'react';


const MobileNav = () => {
    return (
        <div className='mobNavMainPrant  '>

            <div className="navbar">
                <div className='flex gap-40'>

                    <Link to="/">
                        <img src={logo} className="w-24" />
                    </Link>
                </div>
                <ul class="menuNav">
                <li class=" hover:cursor-pointer"  >
                <Link to="/">
                <h6 className='text-sm'>
                Home
                </h6>
                </Link>
                </li >

                <li class=" hover:cursor-pointer"  >
                <Link to="/contactus">
                <h4  className='text-sm'>
                Contactus
                </h4>
                </Link>

                </li >

                <li class=" hover:cursor-pointer"  >
                <Link to="/Aboutus">
                <h4 className='text-sm'>
                Aboutus
                </h4>
                </Link>

                </li >
                </ul>



            </div>
        </div>
    )
}

export default MobileNav