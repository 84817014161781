
// import React, { useState } from 'react';
// import './Newsn.css'
// import logo from "../assets/image15.jpg";
// import logo1 from "../assets/image16.jpg";
// import logo2 from "../assets/image17.jpg";
// import Droga from "../assets/Droga.jpg";

// const FormDownload = () => {
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [department, setDepartment] = useState('');

//   const handleFormDownload = () => {
//     // Logic to generate and download the form
//     const logoUrl = { Droga } // Replace with the actual path to your logo image
//     const formContent = `Name: ${name}\nEmail: ${email}\nDepartment: ${department}`;

//     const template = `
//       <div style="text-align: center;">
//         <img src="${Droga}" alt="Logo" style="max-width: 200px; margin-bottom: 20px;">
//       </div>
//       <h2 style="text-align: center; font-size: 24px;">Form Data:</h2>
//       <pre style="font-size: 18px;">${formContent}</pre>
//     `;

//     const element = document.createElement('a');
//     const file = new Blob([template], { type: 'text/html' });
//     element.href = URL.createObjectURL(file);
//     element.download = 'form.html';
//     document.body.appendChild(element);
//     element.click();
//   };

//   const handleFormSend = () => {
//     // Logic to send the form data to the IT manager
//     console.log('Sending form:', { name, email, department });
//     // Additional logic can be added here, such as making an API call to send the form data
//   };

//   return (
//     <div className="p-4">
//       <h2 className="text-2xl font-bold mb-4">Fill in the Form</h2>
//       <div className="mb-4">
//         <label htmlFor="name" className="block mb-2">Name:</label>
//         <input
//           id="name"
//           type="text"
//           className="border border-gray-300 rounded px-3 py-2 w-full text-lg"
//           value={name}
//           onChange={(e) => setName(e.target.value)}
//         />
//       </div>
//       <div className="mb-4">
//         <label htmlFor="email" className="block mb-2">Email:</label>
//         <input
//           id="email"
//           type="email"
//           className="border border-gray-300 rounded px-3 py-2 w-full text-lg"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//         />
//       </div>
//       <div className="mb-4">
//         <label htmlFor="department" className="block mb-2">Department:</label>
//         <input
//           id="department"
//           type="text"
//           className="border border-gray-300 rounded px-3 py-2 w-full text-lg"
//           value={department}
//           onChange={(e) => setDepartment(e.target.value)}
//         />
//       </div>
//       <button
//         className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-4"
//         onClick={handleFormDownload}
//       >
//         Download Form
//       </button>
//       <button
//         className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
//         onClick={handleFormSend}
//       >
//         Send Form
//       </button>
//     </div>
//   );
// };



// const HomePage = () => {
//   return (
//     <div className="flex flex-col items-center justify-center p-8">
//       <h1 className="text-4xl font-bold mb-4">Welcome to our Droga</h1>
//       <div className="carousel">
//         <img src={logo} alt="Welcome 1" className="carousel-image" />
//         <img src={logo1} alt="Welcome 2" className="carousel-image" />
//         <img src={logo2} alt="Welcome 3" className="carousel-image" />
//       </div>
//       <p className="text-lg">We are here to provide top-notch IT solutions to our organization.</p>
//     </div>
//   );
// };

// const Newsn = () => {
//   return (
//     <div>
//       <HomePage />
//       <FormDownload />
//     </div>
//   );
// };

// export default Newsn;




import logo from "../assets/image15.jpg";
import logo1 from "../assets/image16.jpg";
import logo2 from "../assets/image17.jpg";
import React, { useState, useEffect } from 'react';
import Droga from "../assets/Droga.jpg";


// const FormDownload = () => {
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [department, setDepartment] = useState('');
//   const [youid, setYouid] = useState('');
//   const [description, setDescription] = useState('');

//   const handleFormDownload = () => {
//     // Logic to generate and download the form
//     const logoUrl = '../assets/Droga.jpg'; // Replace with the actual path to your logo image
//     const formContent = `Name: ${name}\nEmail: ${email}\nDepartment: ${department}` + `\nYou ID: ${youid}` + `\nDescription: ${description}`;

//     const template = `
//       <div style="text-align: center;">
//         <img src="${logoUrl}" alt="Logo" style="max-width: 200px; margin-bottom: 20px;">
//       </div>
//       <h2 style="text-align: center; font-size: 24px;">Form Data:</h2>
//       <pre style="font-size: 18px;">${formContent}</pre>
//     `;

//     const element = document.createElement('a');
//     const file = new Blob([template], { type: 'text/html' });
//     element.href = URL.createObjectURL(file);
//     element.download = 'form.html';
//     document.body.appendChild(element);
//     element.click();
//   };

//   const handleFormSend = () => {
//     // Logic to send the form data to the IT manager
//     console.log('Sending form:', { name, email, department ,youid, description });
//     // Additional logic can be added here, such as making an API call to send the form data
//   };

//   return (
//     <div className="p-4 bg-dro_yellow w-2/3 justify-center items-center">
//       <h2 className="text-2xl font-bold mb-4">Fill in the Form</h2>
//       <div className="mb-4">
//         <label htmlFor="name" className="block mb-2">Name:</label>
//         <input
//           id="name"
//           type="text"
//           className="border border-gray-300 rounded px-3 py-2 w-full text-lg"
//           value={name}
//           onChange={(e) => setName(e.target.value)}
//         />
//       </div>
//       <div className="mb-4">
//         <label htmlFor="email" className="block mb-2">Email:</label>
//         <input
//           id="email"
//           type="email"
//           className="border border-gray-300 rounded px-3 py-2 w-full text-lg"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//         />
//       </div>
//       <div className="mb-4">
//         <label htmlFor="department" className="block mb-2">Department:</label>
//         <input
//           id="department"
//           type="text"
//           className="border border-gray-300 rounded px-3 py-2 w-full text-lg"
//           value={department}
//           onChange={(e) => setDepartment(e.target.value)}
//         />
//       </div>
// <div className="mb-4">
//         <label htmlFor="description" className="block mb-2">Description:</label>
//         <input
//           id="description"
//           type="text"
//           className="border border-gray-300 rounded px-3 py-2 w-full text-lg"
//           value={description}
//           onChange={(e) => setDescription(e.target.value)}
//         />
// </div>

//       <div className="mb-4">
//       <label htmlFor="youid" className="block mb-2">You ID:</label>
//       <input
//         id="youid"
//         type="text"
//         className="border border-gray-300 rounded px-3 py-2 w-full text-lg"
//         value={youid}
//         onChange={(e) => setYouid(e.target.value)}
//       />
//     </div>


      
//       <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-4" onClick={handleFormDownload}>
//         Download Form
//       </button>
//       <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" onClick={handleFormSend}>
//         Send Form
//       </button>
//     </div>
//   );
// };




const HomePage = () => {
  const [activeCard, setActiveCard] = useState(0);
  const cards = [
    {
      id: 1,
      title: 'Welcome to Drogapharma ',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. wow1',
      imageMobile: require("../assets/image15.jpg"), // Image for mobile view
      imageDesktop: require("../assets/image15.jpg"), // Image for desktop view
    },
    {
      id: 2,
      title: 'Ready to get started',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. nice 2',
      imageMobile: require("../assets/image16.jpg"), // Image for mobile view
      imageDesktop: require("../assets/image16.jpg"), // Image for desktop view
    },
    {
      id: 3,
      title: 'put your comment on the below form',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. cool 3',
      imageMobile: require("../assets/image17.jpg"), // Image for mobile view
      imageDesktop: require("../assets/image17.jpg"), // Image for desktop view
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveCard((prevIndex) => (prevIndex === cards.length - 1 ? 0 : prevIndex + 1));
    }, 8000);

    return () => clearInterval(interval);
  }, [cards.length]);


  const navigateToFormatDocument = () => {
    window.location.href = '/FormDownload';
  }

  return (
    <div className="container mx-auto overflow-hidden">
      <div className="max-w-5xl mx-auto ">
        <picture>
          {/* Mobile view */}
          <source media="(max-width: 767px)" srcSet={cards[activeCard].imageMobile} />
          {/* Desktop view */}
          <source media="(min-width: 768px)" srcSet={cards[activeCard].imageDesktop} />
          <img className="w-full h-80 object-cover pt-2 shadow-lg rounded-2xl" src={cards[activeCard].imageDesktop} alt={cards[activeCard].title} />
        </picture>
        <div className="bg-gray-100 shadow-4xl rounded-2xl overflow-hidden my-8">
          <div className="=p-0">
            <h2 className="text-2xl font-bold mb-2">{cards[activeCard].title}</h2>
            <p className="text-gray-600 mb-4">{cards[activeCard].description}</p>
            <button className="bg-black text-white px-4 py-2 rounded-md hover:bg-dro_yellow hover:text-black " onClick={navigateToFormatDocument}>
              Go To requirment format
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Newsn = () => {
  return (
    <div>
      <HomePage />
    </div>
  );
};

export default Newsn;


