// import React from 'react'
// import "../styles/Ingridients.css"

// import ing1 from "../assets/ing1.png";
// import ing2 from "../assets/ing2.png";
// import ing3 from "../assets/ing3.png";
// import ing4 from "../assets/ing4.png";
// import ing5 from "../assets/ing5.png";
// import ing6 from "../assets/ing6.png";
// import test1 from "../assets/test1.png";
// import test2 from "../assets/test2.png";
// import test3 from "../assets/test3.png";
// import test4 from "../assets/test4.png";
// import test5 from "../assets/test5.png";
// import test6 from "../assets/test6.png";

// const Ingridients = () => {
//     return (
//         <div className='ingMainParent'>
//             <p className=' text-4xl ogtext font-medium text-center mb-16'> 
//             Our Values</p>

//             <div className=' flex flex-row gap-40 justify-center'>
//                 <img src={test1} width={50} height={50} className="zoom2" />
//                 <img src={test3} width={50} height={50} className="zoom2" />
//                 <img src={test4} width={50} height={50} className="zoom2" />
//                 <img src={test5} width={50} height={50} className="zoom2" />
//                 <img src={test6} width={50} height={50} className="zoom2" />
//             </div>

//             <div className=' flex flex-row justify-center'>
//                 <p>Integrity </p>
//                 <p>Excellence  </p>
//                 <p>Passion   </p>
//                 <p>Simplicity</p>
//                 <p>Honesty  </p>

//             </div>

//             <br />
//             <br />
//             <br />
//             <br />
//             <br />
//             <br />
//             <br />
//             <br />



//         </div>
//     )
// }

// export default Ingridients
import React from 'react';
import { FaBalanceScale, FaTrophy, FaFire, FaStream } from 'react-icons/fa';

const Ingridients = () => {
  return (
    <div className="flex flex-col items-center justify-center bg-white rounded-lg shadow-md p-4 mx-auto w-64 md:w-auto">
      <h2 className="text-gray-800 text-2xl font-bold mb-4">Our Values</h2>
      <div className="flex flex-col md:flex-row items-center justify-around w-full ">
        <div className="flex flex-col items-center mb-4 md:mb-0">
          <FaBalanceScale className="w-6 h-6 mb-2" />
          <span className="text-gray-800 font-medium">Integrity</span>
        </div>
        <div className="flex flex-col items-center mb-4 md:mb-0">
          <FaTrophy className="w-6 h-6 mb-2" />
          <span className="text-gray-800 font-medium">Excellence</span>
        </div>
        <div className="flex flex-col items-center mb-4 md:mb-0">
          <FaFire className="w-6 h-6 mb-2" />
          <span className="text-gray-800 font-medium">Passion</span>
        </div>
        <div className="flex flex-col items-center mb-4 md:mb-0">
          <FaStream className="w-6 h-6 mb-2" />
          <span className="text-gray-800 font-medium">Simplicity</span>
        </div>
      </div>
    </div>
  );
};

export default Ingridients;