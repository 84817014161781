// import { useState, useEffect, useRef } from 'react';
// import logo from "../assets/cara.png";
// import Message from './Message.png';
// // import TargetIcon from "./icons/TargetIcon";


// function BotChat() {
//   const [userInput, setUserInput] = useState('');
//   const [botResponse, setBotResponse] = useState('');
//   const [isOpen, setIsOpen] = useState(false);
//   const popupRef = useRef(null);

//   useEffect(() => {
//     const handleOutsideClick = (event) => {
//       if (popupRef.current && !popupRef.current.contains(event.target)) {
//         setIsOpen(false);
//       }
//     };

//     document.addEventListener('mousedown', handleOutsideClick);
//     return () => {
//       document.removeEventListener('mousedown', handleOutsideClick);
//     };
//   }, []);


//   const handleUserInput = (event) => {
//     setUserInput(event.target.value);
//   };

//   const handleToggle = () => {
//     setIsOpen((prevState) => !prevState);
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();

//     // API endpoint URL
//     const url = 'http://localhost:8000/api/get-response/';

//     // JSON data to send in the request body
//     const data = {
//       user_input: userInput,
//     };

//     // Send the POST request
//     fetch(url, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(data),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         const botResponse = data.bot_response;
//         setBotResponse(botResponse);
//         setUserInput(''); // Clear user input after receiving the bot response
//       })
//       .catch((error) => {
//         console.error('Error:', error);
//       });
//   };

//   return (
//     <>
//       <div className="fixed bottom-24 right-8 ">
//         <div>
//           <img
//             onClick={handleToggle}
//             src={Message}
//             height={40}
//             width={40}
//             alt="Message"
//             className='animate-[bounce_20.8s_infinite_alternate]  hover:animate-none rounded-full  flex items-center justify-center hover:cursor-pointer'
//           />
//         </div>
      
//       </div>
//       {isOpen ? (
//         <div className="absolute right-0 bottom-12 p-4" ref={popupRef}>
//           <div className="max-w-md w-full bg-yellow shadow-lg rounded-lg p-4">
//             <h2 className="text-lg font-bold mb-1">DROGA SUPPORT</h2>
//             <img
//               className='py-3'  
//               src={Message}
//               height={40}
//               width={40}
//               alt="Droga"
//             />

//             <div className="h-40 bg-gray overflow-auto mb-4 p-2">
//               <p className="text-sm leading-tight">{botResponse}</p>
//             </div>
//             <form onSubmit={handleSubmit}>
//               <input
//                 type="text"
//                 value={userInput}
//                 onChange={handleUserInput}
//                 className="w-full border border-gray rounded-lg px-4 py-2 mb-4 text-black dark:text-white"
//                 placeholder="Ask the bot..."
//               />
//               <button
//                 type="submit"
//                 className="bg-black text-white rounded-lg px-4 py-2"
//               >
//                 Send
//               </button>
//             </form>
//           </div>
//         </div>
//       ) : null}
//     </>
//   );
// }

// export default BotChat;





import { useState, useEffect, useRef } from 'react';
import logo from "../assets/cara.png";
import Message from './Message.png';
import Message1 from './Message1.png';
// import TargetIcon from "./icons/TargetIcon";


function BotChat() {
  const [userInput, setUserInput] = useState('');
  const [botResponse, setBotResponse] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);


  const handleUserInput = (event) => {
    setUserInput(event.target.value);
  };

  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // API endpoint URL
    const url = 'https://api.synergyindustri.com/api/get_response/';

    // JSON data to send in the request body
    const data = {
      user_input: userInput,
    };

    // Send the POST request
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        const botResponse = data.bot_response;
        setBotResponse(botResponse);
        setUserInput(''); // Clear user input after receiving the bot response
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <>
      <div className="fixed bottom-10 right-8">
        <div className=''>
          <img
            onClick={handleToggle}
            src={Message}
            height={70}
            width={70}
            alt="Message"
            className='animate-[bounce_2.8s_infinite_alternate]  hover:animate-none  flex items-center justify-center hover:cursor-pointer rounded-none'
          />
        </div>
      </div>
      {isOpen ? (
        <div className="fixed bottom-24 right-8 z-10" ref={popupRef}>
          <div className="max-w-md w-full bg-orange-400 shadow-lg rounded-lg p-4">
            <h2 className="text-lg font-bold mb-1 text-white">WELCOME TO SYNERGY</h2>
            <img
              className='py-3'  
              src={logo}
              height={60}
              width={60}
              alt="Synergy"
            />

            <div className="h-40 bg-gray-200 overflow-auto mb-4 p-2 rounded-lg">
              <p className="text-sm leading-tight">{botResponse}</p>
            </div>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                value={userInput}
                onChange={handleUserInput}
                className="w-full border border-gray rounded-lg px-4 py-2 mb-4 text-black dark:text-white"
                placeholder="Ask the bot..."
              />
              <button
                type="submit"
                className="bg-synergy-blue hover:bg-blue-900 text-white rounded-lg px-4 py-2"
              >
                Send
              </button>
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default BotChat;