    import React from 'react';

const TechnologicalSolutions = () => {
  const solutions = [
    {
      id: 1,
      title: 'Solution 1',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
      image: '/solution1.jpg',
    },
    {
      id: 2,
      title: 'Solution 2',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
      image: '/solution2.jpg',
    },
    {
      id: 3,
      title: 'Solution 3',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
      image: '/solution3.jpg',
    },
  ];

  return (
    <div className="container mx-auto">
      <h1 className="text-4xl font-bold my-8">Our Technological Solutions</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {solutions.map((solution) => (
          <div key={solution.id} className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg">
            <img className="w-full h-48 object-cover" src={solution.image} alt={solution.title} />
            <div className="p-4">
              <h3 className="text-xl font-medium mb-2">{solution.title}</h3>
              <p className="text-gray-600">{solution.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TechnologicalSolutions;